import api from "./api/middleware";
import app from "./app/middleware";
import ui from "./ui/middleware";
import application from "./application/middleware";
import authentication from "./authentication/middleware";
const rootmiddleware = [
	...application,
	...app,
	...api,
	...ui,
	...authentication,
];

export default rootmiddleware;
