import { createAction } from "@reduxjs/toolkit";
import { GenerateUserConsentOTPRequest } from "../../services/Application/models/GenerateUserConsent";
import { GetPincodeDetailsRequest } from "../../services/Application/models/GetPincodeDetailsRequest";
import { ResendUserConsentOTPRequest } from "../../services/Application/models/ResendUserConsentOTP";
import { VerifyUserConsentOTPRequest } from "../../services/Application/models/VerifyUserConsent";
import { actionRequest } from "../helper";
import { InstaApplicationDraft } from "../../services/Application/models/InstaApplicationDraft";
import { GetPanCardDetailsRequest } from "../../services/Application/models/GetPanCardDetailsRequest";
import { SendOrResendInstaApplicantConsentOtpRequest } from "../../services/Application/models/SendOrResendInstaApplicantConsentOtpRequest";
import { VerifyInstaApplicantConsentOtpRequest } from "../../services/Application/models/VerifyInstaApplicantConsentOtpRequest";
import { AddressProofFetchDetailsRequest } from "../../services/Application/models/GetAddressProofDetails";
import { ExtractDetailsFromAddressProofRequest } from "../../services/Application/models/ExtractDetailsFromAddress";
import { UpdateKycDetailsRequest } from "../../services/Application/models/UpdateKycDetails";
import { AddVerifiedUserAddressDetailsRequest } from "../../services/Application/models/AddUserVerifiedDetails";
import { InstaKYCInitRequest } from "services/Application/models/InstaKYCInit";
import { InstaKYCResultRequest } from "services/Application/models/InstaKYCResult";
export const actions = {
	FETCH_PINCODE_DETAILS: "FETCH_PINCODE_DETAILS",
	GENERATE_USER_CONSENT_OTP: "GENERATE_USER_CONSENT_OTP",
	VERIFY_USER_CONSENT_OTP: "VERIFY_USER_CONSENT_OTP",
	RESEND_USER_CONSENT_OTP: "RESEND_USER_CONSENT_OTP",
	GET_OR_CREATE_INSTA_APPLICATION_DRAFT:
        "GET_OR_CREATE_INSTA_APPLICATION_DRAFT",
	UPDATE_INSTA_APPLICATION_DRAFT: "UPDATE_INSTA_APPLICATION_DRAFT",
	FETCH_PANCARD_DETAILS: "FETCH_PANCARD_DETAILS",
	CREATE_INSTA_APPLICATION_B2C: "CREATE_INSTA_APPLICATION_B2C",
	SEND_OR_RESEND_INSTA_APPLICANT_CONSENT_OTP:
        "SEND_OR_RESEND_INSTA_APPLICANT_CONSENT_OTP",
	VERIFY_INSTA_APPLICANT_CONSENT_OTP: "VERIFY_INSTA_APPLICANT_CONSENT_OTP",
	FETCH_ADDRESS_PROOF_DETAILS: "FETCH_ADDRESS_PROOF_DETAILS",
	EXTRACT_ADDRESS_PROOF_DETAILS: "EXTRACT_ADDRESS_PROOF_DETAILS",
	REVIEW_ADDRESS_PROOF_DETAILS: "REVIEW_ADDRESS_PROOF_DETAILS",
	FETCH_PHOTO_IDENTITY_PROOF_DETAILS: "FETCH_PHOTO_IDENTITY_PROOF_DETAILS",
	EXTRACT_PHOTO_IDENTITY_PROOF_DETAILS:
        "EXTRACT_PHOTO_IDENTITY_PROOF_DETAILS",
	REVIEW_PHOTO_IDENTITY_PROOF_DETAILS: "REVIEW_PHOTO_IDENTITY_PROOF_DETAILS",
	UPDATE_KYC_DETAILS: "UPDATE_KYC_DETAILS",
	ADD_USER_VERIFIED_ADDRESS: "ADD_USER_VERIFIED_ADDRESS",
	INIT_INSTA_KYC: "INIT_INSTA_KYC",
	INSTA_KYC_RESULT: "INSTA_KYC_RESULT",
	GET_INSTA_APPLICATION_DETAILS: "GET_INSTA_APPLICATION_DETAILS",
};

export const fetchPincodeDetails = createAction<GetPincodeDetailsRequest>(
	actionRequest(actions.FETCH_PINCODE_DETAILS),
);
/* eslint-disable no-mixed-spaces-and-tabs */
export const generateUserConsentOtp =
    createAction<GenerateUserConsentOTPRequest>(
    	actionRequest(actions.GENERATE_USER_CONSENT_OTP),
    );

export const verifyUserConsentOtp = createAction<VerifyUserConsentOTPRequest>(
	actionRequest(actions.VERIFY_USER_CONSENT_OTP),
);

export const resendUserConsentOtp = createAction<ResendUserConsentOTPRequest>(
	actionRequest(actions.RESEND_USER_CONSENT_OTP),
);

export const getOrCreateInstaApplicationDraft = createAction<{
    UTMParams: string
}>(actionRequest(actions.GET_OR_CREATE_INSTA_APPLICATION_DRAFT));

export const updateInstaApplicationDraft = createAction<InstaApplicationDraft>(
	actionRequest(actions.UPDATE_INSTA_APPLICATION_DRAFT),
);

export const fetchPanCardDetails = createAction<GetPanCardDetailsRequest>(
	actionRequest(actions.FETCH_PANCARD_DETAILS),
);

export const createInstaApplicationB2C = createAction<{ UTMParams: string }>(
	actionRequest(actions.CREATE_INSTA_APPLICATION_B2C),
);

export const sendOrResendInstaApplicantConsentOtp =
    createAction<SendOrResendInstaApplicantConsentOtpRequest>(
    	actionRequest(actions.SEND_OR_RESEND_INSTA_APPLICANT_CONSENT_OTP),
    );

export const verifyInstaApplicantConsentOtp =
    createAction<VerifyInstaApplicantConsentOtpRequest>(
    	actionRequest(actions.VERIFY_INSTA_APPLICANT_CONSENT_OTP),
    );

export const fetchAddressProofDetails =
    createAction<AddressProofFetchDetailsRequest>(
    	actionRequest(actions.FETCH_ADDRESS_PROOF_DETAILS),
    );

export const extractAddressProofDetails =
    createAction<ExtractDetailsFromAddressProofRequest>(
    	actionRequest(actions.EXTRACT_ADDRESS_PROOF_DETAILS),
    );

export const reviewAddressProofDetails =
    createAction<ExtractDetailsFromAddressProofRequest>(
    	actionRequest(actions.REVIEW_ADDRESS_PROOF_DETAILS),
    );

export const fetchPhotoIdentityProofDetails =
    createAction<AddressProofFetchDetailsRequest>(
    	actionRequest(actions.FETCH_PHOTO_IDENTITY_PROOF_DETAILS),
    );

export const extractPhotoIdentityProofDetails =
    createAction<ExtractDetailsFromAddressProofRequest>(
    	actionRequest(actions.EXTRACT_PHOTO_IDENTITY_PROOF_DETAILS),
    );

export const reviewPhotoIdentityProofDetails =
    createAction<ExtractDetailsFromAddressProofRequest>(
    	actionRequest(actions.REVIEW_PHOTO_IDENTITY_PROOF_DETAILS),
    );

export const updateKycDetails = createAction<UpdateKycDetailsRequest>(
	actionRequest(actions.UPDATE_KYC_DETAILS),
);

export const addUserVerifiedDetails =
    createAction<AddVerifiedUserAddressDetailsRequest>(
    	actionRequest(actions.ADD_USER_VERIFIED_ADDRESS),
    );

export const initInstaKYC = createAction<InstaKYCInitRequest>(
	actionRequest(actions.INIT_INSTA_KYC),
);

export const updateInstaKYCResult = createAction<InstaKYCResultRequest>(
	actionRequest(actions.INSTA_KYC_RESULT),
);

export const getInstaDetails = createAction(
	actionRequest(actions.GET_INSTA_APPLICATION_DETAILS),
);
