import { NetworkRequest } from "../models/NetworkRequest";
import NetworkRequestHelper from "../helper";
import baseUrl from "../../config/service";
import { GetPincodeDetailsRequest } from "./models/GetPincodeDetailsRequest";
import { GenerateUserConsentOTPRequest } from "./models/GenerateUserConsent";
import { VerifyUserConsentOTPRequest } from "./models/VerifyUserConsent";
import { ResendUserConsentOTPRequest } from "./models/ResendUserConsentOTP";
import { InstaApplicationDraft } from "./models/InstaApplicationDraft";
import { SendOrResendInstaApplicantConsentOtpRequest } from "./models/SendOrResendInstaApplicantConsentOtpRequest";
import { VerifyInstaApplicantConsentOtpRequest } from "./models/VerifyInstaApplicantConsentOtpRequest";
import { AddressProofFetchDetailsRequest } from "./models/GetAddressProofDetails";
import { ExtractDetailsFromAddressProofRequest } from "./models/ExtractDetailsFromAddress";
import { UpdateKycDetailsRequest } from "./models/UpdateKycDetails";
import { AddVerifiedUserAddressDetailsRequest } from "./models/AddUserVerifiedDetails";
import { InstaKYCInitRequest } from "./models/InstaKYCInit";
import { InstaKYCResultRequest } from "./models/InstaKYCResult";
class CustomerService 
{
	static getPincodeDetailsForApplication = (
		data: GetPincodeDetailsRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.GET(
			baseUrl.staticdata + "/pincode/search",
			data,
		);
	};

	static generateUserConsentOTP = (
		data: GenerateUserConsentOTPRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.customer + "/details/add",
			data,
		);
	};

	static verifyUserConsentOTP = (
		data: VerifyUserConsentOTPRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.customer + "/consent/verify-otp-and-eval-credit",
			data,
		);
	};

	static resendUserConsentOTP = (
		data: ResendUserConsentOTPRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.GET(
			baseUrl.customer + "/consent/resend-otp",
			data,
		);
	};
	/* eslint-disable  @typescript-eslint/no-explicit-any */

	static getOrCreateInstaApplicationDraft = (data: any): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-creation/b2c/user/get-create",
			data,
		);
	};

	static updateInstaApplicationDraft = (
		data: InstaApplicationDraft,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-creation/b2c/user/update-draft",
			data,
		);
	};

	static fetchPanCardDetails = (
		data: InstaApplicationDraft,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-creation/pan-verification/fetch",
			data,
		);
	};

	static createInstaApplicationB2C = (data: any): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-creation/b2c/user/create",
			data,
		);
	};

	static sendOrResendInstaApplicantConsentOtp = (
		data: SendOrResendInstaApplicantConsentOtpRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-creation/consent/otp/send",
			data,
		);
	};

	static verifyInstaApplicantConsentOtp = (
		data: VerifyInstaApplicantConsentOtpRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-creation/consent/otp/verify",
			data,
		);
	};

	static fetchAddressProofDetails = (
		data: AddressProofFetchDetailsRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-creation/address-proof/fetch",
			data,
		);
	};

	static extractAddressProofDetails = (
		data: ExtractDetailsFromAddressProofRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-creation/address-proof/extract",
			data,
		);
	};

	static extractPhotoIdentityProofDetails = (
		data: ExtractDetailsFromAddressProofRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-creation/photo-identity-proof/extract",
			data,
		);
	};

	static updateKycDetails = (
		data: UpdateKycDetailsRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-application/kyc/details/update",
			data,
		);
	};

	static addUserverifiedAddress = (
		data: AddVerifiedUserAddressDetailsRequest,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway +
                "/insta-application/add-user-verified/address-details",
			data,
		);
	};

	static instaKycInit = (data: InstaKYCInitRequest): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-creation/aadhaar-kyc/init",
			data,
		);
	};

	static instaKycResult = (data: InstaKYCResultRequest): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/insta-creation/aadhaar-kyc/result",
			data,
		);
	};

	static getInstaDetails = (data: any): NetworkRequest => 
	{
		return NetworkRequestHelper.GET(
			baseUrl.apiGateway + "/insta-creation/b2c/user/get/instadetails",
			data,
		);
	};
}

export default CustomerService;
