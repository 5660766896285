import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { LoginOtpFormData } from "./models/LoginOtpData";
import {
	TextField,
	Button,
	CircularProgress,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import ResentOtpTimer from "../OtpTimerComponent";
import { otpRegx } from "../../../../utils/commonRegExs";
import { actions } from "store/authentication/actions";
import { checkForErrors, onSubmitError } from "utils/helpers";
import { removeDoneActions, removeErrors } from "store/ui/actions";
import { RootState } from "config/store";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = (state: RootState) => 
{
	const { ui, authentication } = state;
	return {
		ui: ui,
		userMobile: authentication.userMobile,
		userEmail: authentication.userEmail,
		isThereErrorOnOtp: checkForErrors(
			state.ui.errors,
			actions.USER_MOBILE_OTP_VERIFICATION,
		),
		mobileEmailOtpError: checkForErrors(
			state.ui.errors,
			actions.USER_MOBILE_EMAIL_OTP_VERIFICATION,
		),
		redirectedFromWebflow: authentication.redirectedFromWebflow,
	};
};

const mapDispatchToProps = {
	removeDoneActions,
	removeErrors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

type Props = ReduxProps & {
    userAlreadySignup: any
    onHandleSubmit: any
    otpLoader: any
    resendMobileOtp: any
    resendEmailOtp: any
}

const LoginOtpContainer = (props: Props) => 
{
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		setError,
		formState: { errors },
	} = useForm<LoginOtpFormData>({
		mode: "onChange",
	});

	const {
		userAlreadySignup,
		onHandleSubmit,
		otpLoader,
		resendMobileOtp,
		resendEmailOtp,
		removeErrors,
		ui,
		userMobile,
		userEmail,
		isThereErrorOnOtp,
		mobileEmailOtpError,
		redirectedFromWebflow,
	} = props;

	useEffect(() => 
	{
		if (isThereErrorOnOtp) 
		{
			const err = ui.errors[actions.USER_MOBILE_OTP_VERIFICATION][0];
			const errorMessage = err.description;
			if (err.errType == "field") 
			{
				{
					err.payload.field === "MobileOtp" &&
                        setError("MobileOtp", {
                        	message: errorMessage,
                        	types: {
                        		required: err.description,
                        	},
                        	type: "required",
                        });
				}
			}
			removeErrors([actions.USER_MOBILE_OTP_VERIFICATION]);
		}
	}, [isThereErrorOnOtp]);

	useEffect(() => 
	{
		if (mobileEmailOtpError) 
		{
			const err = ui.errors[actions.USER_MOBILE_EMAIL_OTP_VERIFICATION][0];
			const errorMessage = err.description;
			if (err.errType == "field") 
			{
				{
					err.payload.field === "MobileOtp" &&
                        setError("MobileOtp", {
                        	message: errorMessage,
                        	types: {
                        		required: err.description,
                        	},
                        	type: "required",
                        });
				}
				{
					err.payload.field === "EmailOtp" &&
                        setError("EmailOtp", {
                        	message: errorMessage,
                        	types: {
                        		required: err.description,
                        	},
                        	type: "required",
                        });
				}
			}
			removeErrors([actions.USER_MOBILE_EMAIL_OTP_VERIFICATION]);
		}
	}, [mobileEmailOtpError]);

	const scrollIntoElement = (name: string) => 
	{
		document.getElementById(name)?.scrollIntoView({
			behavior: "smooth",
			inline: "end",
			block: "start",
		});
	};
	const onSubmitError = (data: any) => 
	{
		const errors = Object.keys(data);
		if (errors.length > 0) 
		{
			scrollIntoElement(data[errors[0]]?.ref?.name);
		}
	};
	const onSubmitOtp = (data: any) => 
	{
		//@ts-ignore
		// gtag("event", "conversion", {
		// 	send_to: "AW-10862700578/DuuvCKmu7IUYEKLQ3r\so",
		// });
		onHandleSubmit({
			mobileOTP: data.MobileOtp,
			emailOTP: data.EmailOtp,
		});
	};
	const onClickPrevious = (data: any) => 
	{
		//@ts-ignore
		// gtag("event", "conversion", {
		// 	send_to: "AW-10862700578/DuuvCKmu7IUYEKLQ3r\so",
		// });

		console.log("GO Back");
	};
	return (
		<div className="form-container">
			<div className="form--resent-button">
				<div>
					<p>
                        Mobile Otp <b>{userMobile}</b>
					</p>
				</div>
				<div>
					<ResentOtpTimer
						text="Resend Mobile OTP"
						timeLimit_sec={30}
						onClick={resendMobileOtp}
					/>
				</div>
			</div>
			<Controller
				name={"MobileOtp"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Otp is sent to your mobile",
					},
					pattern: {
						value: otpRegx,
						message: "Invalid OTP!",
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							variant="outlined"
							placeholder="OTP sent to your mobile"
							error={!!errors.MobileOtp}
							helperText={errors?.MobileOtp?.message}
							InputProps={{
								id: "MobileOtp",
							}}
						/>
					);
				}}
			/>
			{!userAlreadySignup && (
				<>
					<div className="form--resent-button">
						<div>
							<p>
                                Email Otp <b>{userEmail}</b>
							</p>
						</div>
						<div>
							<ResentOtpTimer
								text="Resend Email OTP"
								timeLimit_sec={30}
								onClick={resendEmailOtp}
							/>
						</div>
					</div>
					<Controller
						name={"EmailOtp"}
						control={control}
						rules={{
							required: {
								value: true,
								message: "Otp is sent to you Email",
							},
							pattern: {
								value: otpRegx,
								message: "Invalid OTP!",
							},
						}}
						render={({ field }) => 
						{
							return (
								<TextField
									onChange={field.onChange}
									value={field.value}
									variant="outlined"
									placeholder="OTP sent to your Email"
									error={!!errors.EmailOtp}
									helperText={errors?.EmailOtp?.message}
									InputProps={{
										id: "EmailOtp",
									}}
								/>
							);
						}}
					/>
				</>
			)}
			{redirectedFromWebflow && (
				<>
					<FormControlLabel
						control={
							<Checkbox
								disabled
								checked
								inputProps={{ "aria-label": "controlled" }}
							/>
						}
						label={
							<span className="consent__label">
                                I understand the loan is for education-related
                                purpose only
							</span>
						}
					/>
					<FormControlLabel
						control={
							<Checkbox
								disabled
								checked
								inputProps={{ "aria-label": "controlled" }}
							/>
						}
						label={
							<span className="consent__label">
                                I consent to Propelld and/or its Lenders
                                receiving my credit information and confirm that
                                I have read, understood and agree to comply with
                                the{" "}
								<a
									href="https://www.propelld.com/terms"
									target="_blank"
									style={{ color: "#5476ff" }}
									rel="noreferrer"
								>
                                    Terms of Service
								</a>
								{", "}
								<a
									href="https://www.propelld.com/privacy"
									target="_blank"
									style={{ color: "#5476ff" }}
									rel="noreferrer"
								>
                                    Privacy Policy
								</a>
								{" and "}
								<a
									href="https://www.propelld.com/credit-terms"
									target="_blank"
									style={{ color: "#5476ff" }}
									rel="noreferrer"
								>
                                    Credit Information Terms and Conditions.
								</a>
							</span>
						}
					/>
					<FormControlLabel
						control={
							<Checkbox
								disabled
								checked
								inputProps={{ "aria-label": "controlled" }}
							/>
						}
						label={
							<span className="consent__label">
                                I agree to allow Propelld to call me or send me
                                important information and updates over WhatsApp
                                overriding my registration with NDNC.
							</span>
						}
					/>
				</>
			)}
			<Button
				type="submit"
				onClick={handleSubmit(onSubmitOtp, onSubmitError)}
				variant="contained"
				color="primary"
				fullWidth
				startIcon={
					otpLoader ? (
						<CircularProgress
							style={{ color: "white" }}
							size="1rem"
						/>
					) : undefined
				}
				style={{
					backgroundColor: "#5383f9",
					color: "white",
				}}
			>
				{"Verify OTP"}
			</Button>
			{/* <Button
				onClick={handleSubmit(onClickPrevious, onSubmitError)}
				variant="outlined"
				color="primary"
				fullWidth
				style={{
					color: "#5383f9",
				}}
			>
				{"Go Back"}
			</Button> */}
		</div>
	);
};

export default connector(LoginOtpContainer);
