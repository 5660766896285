import { NetworkRequest } from "../../services/models/NetworkRequest";

export const actions = {
	API_REQUEST: "API_REQUEST",
};
/* eslint-disable  @typescript-eslint/no-explicit-any */

export const apiRequest = (
	request: NetworkRequest,
	onSuccess: any,
	onError: any,
	options?: any,
) => ({
	type: actions.API_REQUEST,
	meta: { request, onSuccess, onError, options },
});
