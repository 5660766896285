import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
import StepConnector, {
	stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)",
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#5383f9",
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#5383f9",
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
		borderTopWidth: 3,
		borderRadius: 1,
	},
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
	({ theme, ownerState }) => ({
		color:
            theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
		display: "flex",
		height: 22,
		alignItems: "center",
		...(ownerState.active && {
			color: "#5383f9",
		}),
		"& .QontoStepIcon-completedIcon": {
			color: "#5383f9",
			zIndex: 1,
			fontSize: 32,
		},
		"& .QontoStepIcon-circle": {
			width: 8,
			height: 8,
			borderRadius: "50%",
			backgroundColor: "currentColor",
		},
	}),
);

function QontoStepIcon(props: StepIconProps) 
{
	const { active, completed, className } = props;

	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<CheckCircleOutlineRounded className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

const steps = ["", "", "", ""];

export const CustomizedSteppers = ({ step }: any) => 
{
	return (
		<Stack sx={{ width: "100%" }} spacing={4}>
			<Stepper
				alternativeLabel
				activeStep={step}
				connector={<QontoConnector />}
			>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel StepIconComponent={QontoStepIcon}>
							{label}
						</StepLabel>
					</Step>
				))}
			</Stepper>
		</Stack>
	);
};
