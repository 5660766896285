import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@mui/material";

type Props = {
    text: string
    timeLimit_sec: number
    timeLimit_min?: number
    style?: any
    onClick: any
}

const ResentOtpTimer = (props: Props) => 
{
	const [timer, setTimer] = useState(
		props.timeLimit_sec ? props.timeLimit_sec : 30,
	);
	const timeOutCallback = useCallback(
		() => setTimer((currTimer) => currTimer - 1),
		[],
	);

	useEffect(() => 
	{
		timer > 0 && setTimeout(timeOutCallback, 1000);
	}, [timer, timeOutCallback]);

	const resetTimer = function () 
	{
		if (!timer) 
		{
			setTimer(props.timeLimit_sec);
			props.onClick();
		}
	};
	return (
		<div>
			{timer > 0 ? (
				<div style={{ color: "blue" }}>
					{`Resend OTP after ${timer} sec`}
				</div>
			) : (
				<div
					style={{ color: "blue", cursor: "pointer" }}
					onClick={resetTimer}
				>
					{props.text ? props.text : "Resend OTP"}
				</div>
			)}
		</div>
	);
};

export default ResentOtpTimer;
