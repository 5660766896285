import React, { useEffect, useState } from "react";
import { LoginLogicContainer, StepsLogicContainer } from "../../index";
import { RootState } from "../../../../../config/store";
import { connect, ConnectedProps } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { checkForDoneAction } from "../../../../../utils/helpers";
import { actions } from "../../../../../store/authentication/actions";
import { removeDoneActions } from "../../../../../store/ui/actions";
import {
	actions as applicationAction,
	getInstaDetails,
	getOrCreateInstaApplicationDraft,
} from "store/application/actions";
import { ApplicationDraftStatus } from "../StepsLogicContainer/mapper";

const mapStateToProps = (state: RootState) => 
{
	const { authentication, ui, application } = state;
	return {
		authorization: authentication.isAuthenticated,
		isUserLoginOTPVerified: checkForDoneAction(
			ui.doneActions,
			actions.USER_MOBILE_OTP_VERIFICATION,
		),
		isUserSignUpOTPVerified: checkForDoneAction(
			ui.doneActions,
			actions.USER_MOBILE_EMAIL_OTP_VERIFICATION,
		),
		instaDraft:
            application?.userApplication?.Application?.InstaApplicationDraft,
		userApplication: application.userApplication,
		isInstaDetails: checkForDoneAction(
			ui.doneActions,
			applicationAction.GET_INSTA_APPLICATION_DETAILS,
		),
	};
};

const mapDispatchToProps = {
	removeDoneActions,
	getInstaDetails,
	getOrCreateInstaApplicationDraft,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

const MainLogicContainer: React.FC<ReduxProps> = (props) => 
{
	const {
		authorization,
		isUserLoginOTPVerified,
		isUserSignUpOTPVerified,
		removeDoneActions,
		getInstaDetails,
		getOrCreateInstaApplicationDraft,
		instaDraft,
		isInstaDetails,
		userApplication,
	} = props;
	const [searchParams, setSearchParams] = useSearchParams();
	const [step, setStepCount] = useState(0);

	useEffect(() => 
	{
		localStorage.setItem("utmParams", searchParams.toString());
		getInstaDetails();
	}, []);

	useEffect(() => 
	{
		if (isUserLoginOTPVerified) 
		{
			//@ts-ignore
			fbq("track", "MobileOTPVerified");
			getInstaDetails();
			removeDoneActions([actions.USER_MOBILE_OTP_VERIFICATION]);
		}

		if (isUserSignUpOTPVerified) 
		{
			//@ts-ignore
			fbq("track", "MobileOTPVerified");
			getInstaDetails();
			removeDoneActions([actions.USER_MOBILE_EMAIL_OTP_VERIFICATION]);
		}
	}, [isUserLoginOTPVerified, isUserSignUpOTPVerified]);

	useEffect(() => 
	{
		if (isInstaDetails) 
		{
			if (instaDraft?.InstaId) 
			{
				if (userApplication?.IncorrectPan) 
				{
					setStepByInstaDraftStatus("STARTED");
				}
				else 
				{
					setStepByInstaDraftStatus(instaDraft?.Status ?? "");
				}
			}
			else 
			{
				getOrCreateInstaApplicationDraft({
					UTMParams: localStorage.getItem("utmParams") || "",
				});
			}
			removeDoneActions([applicationAction.GET_INSTA_APPLICATION_DETAILS]);
		}
	}, [isInstaDetails]);

	const setStepByInstaDraftStatus = (status: string) => 
	{
		switch (status) 
		{
		case ApplicationDraftStatus.STEP1_COMPLETED:
			return setStepCount(1);
		case ApplicationDraftStatus.STEP2_COMPLETED:
			return setStepCount(2);
		case ApplicationDraftStatus.STEP3_COMPLETED:
			return setStepCount(3);
		case ApplicationDraftStatus.COMPLETED:
			return setStepCount(4);
		default:
			return setStepCount(0);
		}
	};

	return (
		<>
			{!authorization && <LoginLogicContainer />}
			{authorization && (
				<StepsLogicContainer
					step={step}
					setStepCount={setStepCount}
					setStepByInstaDraftStatus={setStepByInstaDraftStatus}
				/>
			)}
		</>
	);
};

export default connector(MainLogicContainer);
