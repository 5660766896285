import React from "react";
import { SideHeader, MainLogicContainer } from "./Components";
import "./style.scss";
const RightSideContainer = () => 
{
	return (
		<form method="dialog" className="form__main" autoComplete={"off"}>
			<div className="rightside--container">
				<SideHeader />
				<MainLogicContainer />
			</div>
		</form>
	);
};

export default RightSideContainer;
