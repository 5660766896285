import { createReducer } from "@reduxjs/toolkit";
import { actions as types } from "./actions";
import { actionRequest, actionSuccess } from "../helper";
import auth from "../../utils/authentication";
export interface State {
    isAuthenticated: boolean
    otpSent: boolean
    userAlreadySignup: boolean
    userName: string[]
    userEmail: string
    userMobile: string
    redirectedFromWebflow: boolean
}

const initialState: State = {
	isAuthenticated: auth.isLoggedIn(),
	otpSent: false,
	userAlreadySignup: false,
	userName: [],
	userEmail: "",
	userMobile: "",
	redirectedFromWebflow: false,
};

export default createReducer(initialState, {
	[actionRequest(types.USER_SIGNUP)]: (state, action) => 
	{
		state.userEmail = action.payload.Email;
		state.userMobile = action.payload.Mobile;
		state.userName = action.payload.Name.split(" ");
	},
	[actionSuccess(types.USER_SIGNUP)]: (state, action) => 
	{
		state.otpSent = action.payload.OptSent;
		state.userAlreadySignup = action.payload.IsSignIn;
	},

	[actionSuccess(types.USER_MOBILE_OTP_VERIFICATION)]: (state, action) => 
	{
		auth.login(action.payload.Token);
		state.isAuthenticated = auth.isLoggedIn();
		state.userEmail = action.payload.Email;
		state.userMobile = action.payload.Mobile;
	},

	[actionSuccess(types.USER_MOBILE_EMAIL_OTP_VERIFICATION)]: (
		state,
		action,
	) => 
	{
		auth.login(action.payload.Token);
		state.isAuthenticated = auth.isLoggedIn();
		state.userEmail = action.payload.Email;
		state.userMobile = action.payload.Mobile;
	},
	[types.SESSION_LOGOUT]: (state, action) => 
	{
		auth.logOut();
		state.isAuthenticated = false;
		state.redirectedFromWebflow = false;
	},
	[actionSuccess(types.GET_WEBFLOW_DETAILS)]: (state, action) => 
	{
		state.userName = action.payload.WebflowData?.Name.split(" ");
		state.userEmail = action.payload.WebflowData?.Email;
		state.userMobile = action.payload.WebflowData?.Mobile;
		state.redirectedFromWebflow = true;
	},
});
