import { createAction } from "@reduxjs/toolkit";

export const actions = {
	UPDATE_APP_LANGUAGE_REQUEST: "UPDATE_APP_LANGUAGE_REQUEST",
	UPDATE_APP_LANGUAGE_SUCCESS: "UPDATE_APP_LANGUAGE_SUCCESS",
	UPDATE_APP_LANGUAGE_FAILED: "UPDATE_APP_LANGUAGE_FAILED",

	SESSION_EXPIRED: "SESSION_EXPIRED",
	UNAUTHORIZED_ACCESS: "UNAUTHORIZED_ACCESS",
	FORBIDDEN_ACCESS: "FORBIDDEN_ACCESS",
};

export const updateAppLanguage = createAction(
	actions.UPDATE_APP_LANGUAGE_REQUEST,
	(newLang: string) => ({
		payload: newLang,
	}),
);
