class Auth 
{
	static isLoggedIn() 
	{
		return !!localStorage.jwt || !!sessionStorage.jwt;
	}
	static getToken() 
	{
		return localStorage.jwt || sessionStorage.jwt || "";
	}
	static login(jwt: string) 
	{
		localStorage.setItem("jwt", jwt);
	}

	static sessionLogin(jwt: string) 
	{
		sessionStorage.setItem("jwt", jwt);
	}

	static logOut() 
	{
		localStorage.removeItem("jwt");
		sessionStorage.removeItem("jwt");
	}
}

export default Auth;
