import ui from "./ui/reducer";
import app from "./app/reducer";
import application from "./application/reducer";
import authentication from "./authentication/reducer";
const rootReducer = {
	ui,
	app,
	application,
	authentication,
};

export default rootReducer;
