const staticdata = "https://pp.propelld.com/api/static";
const customer = "https://pp.propelld.com/api/customer";
const apiGateway = "https://pp.propelld.com/api/api-gateway/propelld";
const uploadDocument = "https://pp.propelld.com/api/";
const reDirectionUrl = "https://pp.loan.propelld.com/";

const baseUrls = {
	customer,
	staticdata,
	apiGateway,
	uploadDocument,
	reDirectionUrl,
};

export default baseUrls;
