import { NetworkRequest } from "../models/NetworkRequest";
import NetworkRequestHelper from "../helper";
import baseUrl from "../../config/service";
import {
	UserSignupRequest,
	UserLoginMobileEmailOtp,
	UserLoginMobileOtp,
	ResendEmailOtpRequest,
	ResendMobileOtpRequest,
} from "./models/UserSignupRequest";

class AuthenticationService 
{
	static userSignup = (data: UserSignupRequest): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/user/b2c/signup/otp",
			data,
		);
	};

	static userLoginWithMobileOtp = (
		data: UserLoginMobileOtp,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/user/login/mobile",
			data,
		);
	};

	static userLoginWithMobileEmailOtp = (
		data: UserLoginMobileEmailOtp,
	): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/user/signup/account",
			data,
		);
	};

	static resendMobileOtp = (data: ResendMobileOtpRequest): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/user/signup/mobile/otp",
			data,
		);
	};

	static resendEmailOtp = (data: ResendEmailOtpRequest): NetworkRequest => 
	{
		return NetworkRequestHelper.POST(
			baseUrl.apiGateway + "/user/signup/email/otp",
			data,
		);
	};

	static fetchWebflowDetails = (mobile: string): NetworkRequest => 
	{
		return NetworkRequestHelper.GET(
			baseUrl.apiGateway + "/insta-creation/b2c/user/webflow/get",
			{
				Mobile: mobile,
			},
		);
	};
}

export default AuthenticationService;
