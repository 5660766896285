const staticdata = "http://localhost:8080/static";
const customer = "http://localhost:8080/customer";
const apiGateway = "http://localhost:12108/api-gateway/propelld";
const uploadDocument = "http://localhost:4002/";
const reDirectionUrl = "http://localhost:3010/";

const baseUrls = {
	customer,
	staticdata,
	apiGateway,
	uploadDocument,
	reDirectionUrl,
};

export default baseUrls;
