import React from "react";
import { Typography } from "@mui/material";
import "./style.scss";
export const SideHeader = () => 
{
	return (
		<div className="header">
			<Typography
				sx={{
					fontSize: 24,
					fontWeight: 700,
				}}
				variant="h1"
			>
                Get your loan approved in 30 seconds
			</Typography>
		</div>
	);
};
