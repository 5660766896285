import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ConnectedProps, connect } from "react-redux";
import {
	TextField,
	Button,
	FormControl,
	FormLabel,
	RadioGroup,
	Radio,
	FormControlLabel,
	Fab,
	CircularProgress,
} from "@mui/material";
import { Step2FormData } from "./models/Step2FormData";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { pincodeRegx } from "utils/commonRegExs";
import { Step2Details } from "../../LogicContainers/StepsLogicContainer";
import { GetPincodeDetailsResponse } from "../../../../../services/Application/models/GetPincodeDetailsResponse";
import moment from "moment";
import {
	actions,
	getOrCreateInstaApplicationDraft,
	updateInstaApplicationDraft,
	fetchPincodeDetails,
} from "../../../../../store/application/actions";
import {
	checkForDoneAction,
	checkForErrors,
	checkForLoading,
	onSubmitError,
} from "../../../../../utils/helpers";
import {
	removeDoneActions,
	removeErrors,
} from "../../../../../store/ui/actions";
import { RootState } from "../../../../../config/store";
import CallUsComponent from "components/CallUsComp";

const mapStateToProps = (state: RootState) => 
{
	const { ui, application } = state;
	return {
		ui: ui,
		applicationDraft:
            application?.userApplication?.Application?.InstaApplicationDraft,
		pincodeLoader: checkForLoading(
			ui.spinners,
			actions.FETCH_PINCODE_DETAILS,
		),
		isPinCodeDetailsFetched: checkForDoneAction(
			ui.doneActions,
			actions.FETCH_PINCODE_DETAILS,
		),
		pincodeDetail: application.pincodeDetails,
		isThereErrorOnPinCodeFetch: checkForErrors(
			state.ui.errors,
			actions.FETCH_PINCODE_DETAILS,
		),
	};
};

const mapDispatchToProps = {
	getOrCreateInstaApplicationDraft,
	updateInstaApplicationDraft,
	fetchPincodeDetails,
	removeDoneActions,
	removeErrors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

type Props = ReduxProps & {
    onSaveStep2Details: (data: Step2Details) => void
    onSetStep: (step: number) => void
    isDetailsUpdating: boolean
}

const Step2 = (props: Props) => 
{
	const {
		onSaveStep2Details,
		onSetStep,
		fetchPincodeDetails,
		pincodeLoader,
		pincodeDetail,
		applicationDraft,
		isPinCodeDetailsFetched,
		ui,
		isThereErrorOnPinCodeFetch,
		isDetailsUpdating,
	} = props;

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		setError,
		formState: { errors },
	} = useForm<Step2FormData>({
		mode: "onChange",
		defaultValues: {
			DateOfBirth: applicationDraft?.Applicant?.DateOfBirth ?? "",
			Gender: applicationDraft?.Applicant?.Gender ?? "",
			PinCode: applicationDraft?.Applicant?.CurrentAddressPincode
				? String(applicationDraft?.Applicant?.CurrentAddressPincode)
				: "",
			HouseNumber: applicationDraft?.Applicant?.CurrentAddressLine1 ?? "",
			AddressLine1:
                applicationDraft?.Applicant?.CurrentAddressLine2 ?? "",
			City: applicationDraft?.Applicant?.CurrentAddressCity ?? "",
			State: applicationDraft?.Applicant?.CurrentAddressState ?? "",
		},
	});
	const [pincodeError, setPincodeError] = useState(undefined);

	const onNextSubmit = (data: Step2FormData) => 
	{
		onSaveStep2Details({
			Gender: data.Gender,
			DateOfBirth: data.DateOfBirth,
			CurrentAddressLine1: data.HouseNumber,
			CurrentAddressLine2: data.AddressLine1,
			CurrentAddressCity: data.City,
			CurrentAddressState: data.State,
			CurrentAddressPincode: Number(data.PinCode),
			CurrentAddressCountry: String(pincodeDetail?.Country),
		});
	};

	useEffect(() => 
	{
		if (pincodeDetail && isPinCodeDetailsFetched) 
		{
			const city = pincodeDetail.City
				? pincodeDetail.City
				: pincodeDetail.District;
			setValue("State", pincodeDetail.State);
			setValue("City", city);
			removeDoneActions([actions.FETCH_PINCODE_DETAILS]);
		}
	}, [pincodeDetail, isPinCodeDetailsFetched]);

	useEffect(() => 
	{
		if (isThereErrorOnPinCodeFetch) 
		{
			const err = ui.errors[actions.FETCH_PINCODE_DETAILS][0];
			const errorMessage = err.description || "Invalid PinCode";
			if (err.errType == "field") 
			{
				setValue("State", "");
				setValue("City", "");
				setError("PinCode", {
					message: errorMessage,
					types: {
						required: err.description,
					},
					type: "required",
				});
				setPincodeError(errorMessage);
			}
			removeErrors([actions.FETCH_PINCODE_DETAILS]);
		}
	}, [isThereErrorOnPinCodeFetch]);

	const onFetchPinCode = (pincode: string) => 
	{
		if (
			/([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})/.test(pincode) !==
            true
		) 
		{
			return;
		}
		if (!pincode) return;
		if (pincode.length !== 6) return;
		if (pincode.length == 6) 
		{
			setTimeout(() => 
			{
				fetchPincodeDetails({ q: pincode });
			}, 300);
		}
	};
	return (
		<div className="form-container">
			<Controller
				name={"DateOfBirth"}
				control={control}
				rules={{
					required: {
						value: true,
						message:
                            "Your DOB will help us in finalizing your loan",
					},
				}}
				render={({ field }) => 
				{
					return (
						<>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									disableFuture
									disableHighlightToday
									inputFormat="DD/MM/YYYY"
									openTo="year"
									views={["year", "month", "day"]}
									label="Date of Birth"
									value={moment(field.value).format(
										"DD/MM/YYYY",
									)}
									onChange={(date: any) => 
									{
										field.onChange(
											date?.format("DD/MM/YYYY"),
										);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											error={!!errors?.DateOfBirth}
											helperText={
												errors?.DateOfBirth?.message
											}
											// InputProps={{
											// 	id: "DateOfBirth",
											// }}
										/>
									)}
								/>
							</LocalizationProvider>
						</>
					);
				}}
			/>
			<FormControl>
				<div className="form--radioGroup form--radioGroup-box">
					<FormLabel id="demo-row-radio-buttons-group-label">
                        Gender
					</FormLabel>
					<Controller
						name={"Gender"}
						control={control}
						render={({ field }) => 
						{
							return (
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
									onChange={field.onChange}
									value={field.value}
								>
									<FormControlLabel
										value="Female"
										control={<Radio />}
										label="Female"
									/>
									<FormControlLabel
										value="Male"
										control={<Radio />}
										label="Male"
									/>
									<FormControlLabel
										value="Other"
										control={<Radio />}
										label="Other"
									/>
								</RadioGroup>
							);
						}}
					/>
				</div>
			</FormControl>
			<Controller
				name={"PinCode"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Please add your PinCode",
					},
					pattern: {
						value: pincodeRegx,
						message: "Invalid PinCode!",
					},
					validate: (val) => 
					{
						if (!pincodeDetail) 
						{
							return pincodeError;
						}
						return true;
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={(e) => 
							{
								setPincodeError(undefined);
								onFetchPinCode(e.target.value);
								field.onChange(e.target.value);
							}}
							value={field.value}
							variant="outlined"
							label="Pin Code"
							error={!!errors.PinCode}
							helperText={errors?.PinCode?.message}
							InputProps={{
								id: "PinCode",
								endAdornment: pincodeLoader ? (
									<CircularProgress size="1.5rem" />
								) : null,
							}}
						/>
					);
				}}
			/>
			{pincodeDetail ||
            applicationDraft?.Applicant?.CurrentAddressState ? (
					<>
						<Controller
							name={"HouseNumber"}
							control={control}
							rules={{
								required: {
									value: true,
									message: "Please add your House Number",
								},
							}}
							render={({ field }) => 
							{
								return (
									<TextField
										onChange={field.onChange}
										value={field.value}
										variant="outlined"
										label="House Number"
										error={!!errors.HouseNumber}
										helperText={errors?.HouseNumber?.message}
										InputProps={{
											id: "HouseNumber",
										}}
									/>
								);
							}}
						/>
						<Controller
							name={"AddressLine1"}
							control={control}
							render={({ field }) => 
							{
								return (
									<TextField
										onChange={field.onChange}
										value={field.value}
										variant="outlined"
										label="Address Line 1"
										error={!!errors.AddressLine1}
										helperText={errors?.AddressLine1?.message}
										InputProps={{
											id: "AddressLine1",
										}}
									/>
								);
							}}
						/>
						<Controller
							name={"City"}
							control={control}
							rules={{
								required: {
									value: true,
									message: "Please add your City",
								},
							}}
							render={({ field }) => 
							{
								return (
									<TextField
										onChange={field.onChange}
										value={field.value}
										variant="outlined"
										label={"City"}
										error={!!errors.City}
										helperText={errors?.City?.message}
										InputProps={{
											id: "City",
										}}
									/>
								);
							}}
						/>
						<Controller
							name={"State"}
							control={control}
							render={({ field }) => 
							{
								return (
									<TextField
										onChange={field.onChange}
										value={field.value}
										variant="outlined"
										label="State"
										disabled
									/>
								);
							}}
						/>
					</>
				) : null}
			<Button
				onClick={handleSubmit(onNextSubmit, onSubmitError)}
				variant="outlined"
				color="primary"
				fullWidth
				startIcon={
					isDetailsUpdating ? (
						<CircularProgress
							style={{ color: "white" }}
							size="1rem"
						/>
					) : undefined
				}
				style={{
					backgroundColor: "#5383f9",
					color: "white",
				}}
			>
				{"Next Step"}
			</Button>
			<Button
				onClick={() => 
				{
					props.onSetStep(0);
				}}
				variant="outlined"
				color="primary"
				fullWidth
				// startIcon={
				// 	props.verifyOTPLoader ? (
				// 		<CircularProgress
				// 			style={{ color: "white" }}
				// 			size="1rem"
				// 		/>
				// 	) : undefined
				// }
				style={{
					color: "#5383f9",
				}}
			>
				{"Previous Step"}
			</Button>
			{/* <CallUsComponent /> */}
		</div>
	);
};

export default connector(Step2);
