/* eslint-disable @typescript-eslint/ban-types */
import { actions as types } from "./actions";
import { checkStatus, checkResponseCode } from "../../utils/apiUtils";
import { Middleware, AnyAction } from "redux";
import { NetworkRequest } from "../../services/models/NetworkRequest";
import queryString from "query-string";
import { ApiError } from "../../models/ApiError";
import ApiErrorHelper from "../../models/ApiErrorHelper";
import Auth from "../../utils/authentication";
import { NetworkErrorCodes } from "../../models/NetworkErrorCodes";
import { actions } from "../app/actions";
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const api: Middleware =
    ({ dispatch }) =>
    	(next) =>
    		async (
    			action: AnyAction & {
            meta: {
                request: NetworkRequest
                onSuccess: Function
                onError: Function
                options: any
            }
        },
    		) => 
    		{
    			if (action.type === types.API_REQUEST) 
    			{
    				const { request, onSuccess, onError } = action.meta;

    				request.headers["Content-Type"] = "application/json";
    				request.headers["x-access-token"] = Auth.getToken();

    				const options = {
    					method: request.method,
    					headers: request.headers,
    				} as RequestInit;

    				if (request.method === "POST") 
    				{
    					options.body = JSON.stringify(request.body);
    				}

    				fetch(
    					request.url + "?" + queryString.stringify(request.params),
    					options,
    				)
    					.then(checkStatus)
    					.then(checkResponseCode)
    					.then((response) => response.PayLoad)
    					.then((data) => dispatch({ type: onSuccess, payload: data }))
    					.catch((error: ApiError) => 
    					{
    						if (error?.type === "NetworkError") 
    						{
    							if (
    								error.errors[0].code ===
                            NetworkErrorCodes.UNAUTHORIZED
    							) 
    							{
    								dispatch({ type: actions.UNAUTHORIZED_ACCESS });
    							}
    							else if (
    								error.errors[0].code === NetworkErrorCodes.FORBIDDEN
    							) 
    							{
    								dispatch({ type: actions.FORBIDDEN_ACCESS });
    							}
    							else 
    							{
    								dispatch({
    									type: onError,
    									payload: ApiErrorHelper.getGenericError(
    										"'Something went wrong!",
    									),
    								});
    							}
    						}
    						else if (error?.type === "ServiceError") 
    						{
    							dispatch({ type: onError, payload: error });
    						}
    						else 
    						{
    							dispatch({
    								type: onError,
    								payload: ApiErrorHelper.getGenericError(
    									"'Something went wrong!",
    								),
    							});
    						}
    					});
    			}
    			return next(action);
    		};

export default [api];
