import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import DangerousIcon from "@mui/icons-material/Dangerous";
import "./style.scss";

interface Props {
    title: any
    subTitle: any
    status: "success" | "error" | "info" | "warn" | "404" | "403" | "500"
    icon?: any
    extra?: any
    children?: React.ReactNode
    secondtitle?: any
}

const Result: React.FC<Props> = (props) => 
{
	const { title, subTitle, status, secondtitle } = props;
	const [classFix, setClassFix] = useState("result-comp--default");
	const [_icon, set_icon] = useState(<CheckCircleIcon />);
	useEffect(() => 
	{
		if (status === "success") 
		{
			setClassFix("result-comp--success");
			set_icon(
				<CheckCircleIcon
					style={{ color: "#53c41a", fontSize: "65px" }}
				/>,
			);
		}
		if (status === "error") 
		{
			setClassFix("result-comp--error");
			set_icon(
				<DangerousIcon
					style={{ color: "#f5212d", fontSize: "65px" }}
					fontSize="large"
				/>,
			);
		}
		if (status === "info") 
		{
			setClassFix("result-comp--info");
			set_icon(
				<InfoIcon
					style={{ color: "#1a3cc4", fontSize: "65px" }}
					fontSize="large"
				/>,
			);
		}
		if (status === "warn") 
		{
			setClassFix("result-comp--warn");
			set_icon(
				<WarningIcon
					style={{ color: "#ffef62", fontSize: "65px" }}
					fontSize="large"
				/>,
			);
		}
	}, []);

	return (
		<div className={`result-comp ${classFix}`}>
			<div className="icon">{_icon}</div>
			<div className="title">{title}</div>
			{secondtitle && <div className="secondtitle">{secondtitle}</div>}
			<div className="sub-title">{subTitle}</div>
			<div className="extra">{props.children}</div>
		</div>
	);
};

export default Result;
