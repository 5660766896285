import React, { useEffect } from "react";
import {
	TextField,
	Button,
	FormLabel,
	CircularProgress,
	Alert,
} from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { VerifyAddressData } from "../Modals/VerifyDetails/model/VerifyAddressData";
import { RootState } from "config/store";
import { removeDoneActions, removeErrors } from "store/ui/actions";
import {
	actions,
	addUserVerifiedDetails,
	getOrCreateInstaApplicationDraft,
} from "store/application/actions";
import {
	checkForDoneAction,
	checkForErrors,
	checkForLoading,
} from "utils/helpers";
import { pincodeRegx } from "utils/commonRegExs";
const mapStateToProps = (state: RootState) => 
{
	const { ui, application } = state;
	return {
		ui: ui,
		createdApplication: application.userApplication,
		extractedDetails:
            application?.userApplication?.Application.AddressProofDetails
            	.Details,
		isVerifiedDetailAdded: checkForDoneAction(
			ui.doneActions,
			actions.ADD_USER_VERIFIED_ADDRESS,
		),
		hasVerifiedDetailAdditionFailed: checkForErrors(
			state.ui.errors,
			actions.ADD_USER_VERIFIED_ADDRESS,
		),
		verifiedDetailAddedLoader: checkForLoading(
			ui.spinners,
			actions.ADD_USER_VERIFIED_ADDRESS,
		),
		AddressProofDetails: application.addressProofDetails,
	};
};

const mapDispatchToProps = {
	removeDoneActions,
	removeErrors,
	addUserVerifiedDetails,
	getOrCreateInstaApplicationDraft,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>
type Props = ReduxProps
const VerifyAddressDetails = (props: Props) => 
{
	const {
		extractedDetails,
		createdApplication,
		isVerifiedDetailAdded,
		hasVerifiedDetailAdditionFailed,
		verifiedDetailAddedLoader,
		getOrCreateInstaApplicationDraft,
		AddressProofDetails,
	} = props;
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		setError,
		formState: { errors },
	} = useForm<VerifyAddressData>({
		mode: "onChange",
		defaultValues: {
			Name: AddressProofDetails?.Extraction?.Name ?? "",
			HouseNo: AddressProofDetails?.Extraction?.House ?? "",
			AddressLine: AddressProofDetails?.Extraction?.Address ?? "",
			Pincode: AddressProofDetails?.Extraction?.Pincode ?? "",
		},
	});

	const onSubmitError = (data: any) => 
	{
		const errors = Object.keys(data);
		if (errors.length > 0) 
		{
			scrollIntoElement(data[errors[0]]?.ref?.name);
		}
	};
	const onSubmitVerify = (data: VerifyAddressData) => 
	{
		if (createdApplication) 
		{
			const verifiedData = {
				InstaId:
                    createdApplication?.Application?.InstaApplicationDraft
                    	?.InstaId,
				Name: data.Name,
				IsCoBorrower: false,
				HouseNo: data.HouseNo,
				AddressLine: data.AddressLine,
				Pincode: String(data.Pincode),
			};
			props.addUserVerifiedDetails(verifiedData);
		}
	};
	const scrollIntoElement = (name: string) => 
	{
		document.getElementById(name)?.scrollIntoView({
			behavior: "smooth",
			inline: "end",
			block: "start",
		});
	};

	return (
		<div className="form-container" style={{ width: "100%" }}>
			<h3 style={{ marginTop: "10px" }}>
                Please confirm your Aadhar Details below
			</h3>

			<Alert severity="success">
                Document Upload not required. Since you have already done e-KYC
                we dont need you to upload document.
			</Alert>

			<Controller
				name={"Name"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Name shouldn't be empty!",
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							variant="outlined"
							placeholder="Name"
							error={!!errors.Name}
							label="Name"
							helperText={errors?.Name?.message}
							InputProps={{
								id: "Name",
							}}
						/>
					);
				}}
			/>
			<Controller
				name={"HouseNo"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "House No shouldn't be empty!",
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							variant="outlined"
							placeholder="House No./ Flat No"
							error={!!errors.HouseNo}
							helperText={errors?.HouseNo?.message}
							label="House No./ Flat No"
							InputProps={{
								id: "HouseNo",
							}}
						/>
					);
				}}
			/>
			<Controller
				name={"AddressLine"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Address Line shouldn't be empty!",
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							variant="outlined"
							placeholder="Address Line"
							error={!!errors.AddressLine}
							helperText={errors?.AddressLine?.message}
							label="Address Line"
							InputProps={{
								id: "AddressLine",
							}}
						/>
					);
				}}
			/>
			<Controller
				name={"Pincode"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Pincode shouldn't be empty!",
					},
					pattern: {
						value: pincodeRegx,
						message: "Invalid Pincode!",
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							variant="outlined"
							fullWidth
							placeholder="Pincode"
							error={!!errors.Pincode}
							helperText={errors?.Pincode?.message}
							label="Pincode"
							InputProps={{
								id: "Pincode",
							}}
						/>
					);
				}}
			/>
			<Button
				onClick={handleSubmit(onSubmitVerify, onSubmitError)}
				variant="contained"
				color="primary"
				fullWidth
				startIcon={
					verifiedDetailAddedLoader ? (
						<CircularProgress
							style={{ color: "white" }}
							size="1rem"
						/>
					) : undefined
				}
				style={{
					backgroundColor: "#5383f9",
					color: "white",
				}}
			>
				{"Verify"}
			</Button>
		</div>
	);
};

export default connector(VerifyAddressDetails);
