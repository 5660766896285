import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import { LoginContainer, LoginOtpContainer } from "../../index";
import { RootState } from "../../../../../config/store";
import { CircularProgress } from "@mui/material";
import {
	actions,
	userSignup,
	userMobileOtpVerification,
	userMobileEmailOtpVerification,
	resendEmailOtp,
	resendMobileOtp,
	getWebflowDetails,
} from "../../../../../store/authentication/actions";
import {
	removeDoneActions,
	removeErrors,
} from "../../../../../store/ui/actions";
import {
	checkForDoneAction,
	checkForErrors,
	checkForLoading,
} from "../../../../../utils/helpers";

const mapStateToProps = (state: RootState) => 
{
	const { ui, authentication } = state;
	return {
		otpSent: authentication.otpSent,
		userAlreadySignup: authentication.userAlreadySignup,
		otpMobileLoader: checkForLoading(
			ui.spinners,
			actions.USER_MOBILE_OTP_VERIFICATION,
		),
		isMobileOtpFetched: checkForDoneAction(
			ui.doneActions,
			actions.USER_MOBILE_OTP_VERIFICATION,
		),
		otpMobileEmailLoader: checkForLoading(
			ui.spinners,
			actions.USER_MOBILE_EMAIL_OTP_VERIFICATION,
		),
		isMobileEmailOtpFetched: checkForDoneAction(
			ui.doneActions,
			actions.USER_MOBILE_EMAIL_OTP_VERIFICATION,
		),
		userSignupLoader: checkForLoading(ui.spinners, actions.USER_SIGNUP),
		isUserSignupDone: checkForDoneAction(
			ui.doneActions,
			actions.USER_SIGNUP,
		),
		isWebflowDetailsFetched: checkForDoneAction(
			ui.doneActions,
			actions.GET_WEBFLOW_DETAILS,
		),
		failedToFetchWebflowData: checkForErrors(
			ui.errors,
			actions.GET_WEBFLOW_DETAILS,
		),
		isUserSignupFailed: checkForErrors(ui.errors, actions.USER_SIGNUP),
		userMobile: authentication.userMobile,
		userEmail: authentication.userEmail,
		userName: authentication.userName,
	};
};

const mapDispatchToProps = {
	userSignup,
	userMobileOtpVerification,
	userMobileEmailOtpVerification,
	resendEmailOtp,
	resendMobileOtp,
	removeDoneActions,
	getWebflowDetails,
	removeErrors,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

const LoginLogicContainer: React.FC<ReduxProps> = (props) => 
{
	const [loading, setLoading] = useState(true);
	const [searchParams] = useSearchParams();
	const {
		otpSent,
		userAlreadySignup,
		userSignupLoader,
		isUserSignupDone,
		otpMobileLoader,
		otpMobileEmailLoader,
		isMobileOtpFetched,
		isMobileEmailOtpFetched,
		resendEmailOtp,
		resendMobileOtp,
		removeDoneActions,
		isWebflowDetailsFetched,
		failedToFetchWebflowData,
		removeErrors,
		isUserSignupFailed,
	} = props;

	useEffect(() => 
	{
		const transactionId = searchParams.get("user");
		if (transactionId) 
		{
			props.getWebflowDetails(transactionId);
		}
		else 
		{
			setLoading(false);
		}
	}, []);

	useEffect(() => 
	{
		const {
			isMobileOtpFetched,
			isMobileEmailOtpFetched,
			isUserSignupDone,
		} = props;
		if (isMobileOtpFetched) 
		{
			removeDoneActions([actions.USER_MOBILE_OTP_VERIFICATION]);
		}
		if (isMobileEmailOtpFetched) 
		{
			removeDoneActions([actions.USER_MOBILE_EMAIL_OTP_VERIFICATION]);
		}
		if (isUserSignupDone) 
		{
			//@ts-ignore
			fbq("track", "BasicInfoComplete");
			removeDoneActions([actions.USER_SIGNUP]);
			setLoading(false);
		}
		if (isWebflowDetailsFetched) 
		{
			removeDoneActions([actions.GET_WEBFLOW_DETAILS]);
			onHandleLogin({
				Name: props.userName.join(" "),
				Mobile: props.userMobile,
				Email: props.userEmail,
			});
		}

		if (failedToFetchWebflowData) 
		{
			removeErrors([actions.GET_WEBFLOW_DETAILS]);
			setLoading(false);
		}
		if (loading && isUserSignupFailed) 
		{
			setLoading(false);
		}
	}, [
		isMobileOtpFetched,
		isMobileEmailOtpFetched,
		isUserSignupDone,
		isWebflowDetailsFetched,
		failedToFetchWebflowData,
		isUserSignupFailed,
	]);

	const onHandleLogin = (values: any) => 
	{
		props.userSignup(values);
	};
	const onHandleLoginMobile = (values: any) => 
	{
		props.userMobileOtpVerification(values);
	};
	const onHandleLoginMobileEmail = (values: any) => 
	{
		props.userMobileEmailOtpVerification(values);
	};
	const onHandleOtpSubmit = (value: any) => 
	{
		const { userMobile, userEmail, userName } = props;
		if (userAlreadySignup) 
		{
			const data = {
				mobile: userMobile,
				mobileOTP: value.mobileOTP,
			};
			onHandleLoginMobile(data);
		}
		else 
		{
			const data = {
				mobile: userMobile,
				mobileOTP: value.mobileOTP,
				email: userEmail,
				emailOTP: value.emailOTP,
				FirstName: userName[0],
				LastName: userName[1] ? userName[1] : "",
			};
			onHandleLoginMobileEmail(data);
		}
	};
	const handleResendMobileOtp = (value: any) => 
	{
		const { userMobile } = props;
		resendMobileOtp({
			mobile: userMobile,
		});
	};

	const handleResendEmailMobileOtp = (value: any) => 
	{
		const { userEmail, userName } = props;
		resendEmailOtp({
			email: userEmail,
			name: userName[0],
		});
	};
	if (loading) 
	{
		return <CircularProgress style={{ margin: "auto" }} />;
	}
	return (
		<>
			{!otpSent && (
				<LoginContainer
					onHandleLogin={onHandleLogin}
					userSignupLoader={userSignupLoader}
					isUserSignupDone={isUserSignupDone}
				/>
			)}
			{otpSent && (
				<LoginOtpContainer
					userAlreadySignup={userAlreadySignup}
					onHandleSubmit={onHandleOtpSubmit}
					otpLoader={
						userAlreadySignup
							? otpMobileLoader
							: otpMobileEmailLoader
					}
					resendMobileOtp={handleResendMobileOtp}
					resendEmailOtp={handleResendEmailMobileOtp}
				/>
			)}
		</>
	);
};

export default connector(LoginLogicContainer);
