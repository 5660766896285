import { Step1Details, Step2Details, Step3Details, Step4Details } from ".";
import { InstaApplicationDraft } from "../../../../../services/Application/models/InstaApplicationDraft";

export const ApplicationDraftStatus = {
	STARTED: "STARTED",
	STEP1_COMPLETED: "STEP1_COMPLETED",
	STEP2_COMPLETED: "STEP2_COMPLETED",
	STEP3_COMPLETED: "STEP3_COMPLETED",
	STEP4_COMPLETED: "STEP4_COMPLETED",
	COMPLETED: "COMPLETED",
};

class Mapper 
{
	public Step1DetailsMapper(
		data: InstaApplicationDraft,
		updatedDetails: Step1Details,
	): InstaApplicationDraft 
	{
		return {
			...data,
			Status: ApplicationDraftStatus.STEP1_COMPLETED,
			LoanDetails: {
				LoanAmount: updatedDetails.LoanAmount,
				CourseName: updatedDetails.CourseName,
				InstituteName: updatedDetails.InstituteName,
			},
			//@ts-ignore
			Applicant: {
				...data.Applicant,
				IsEarning: updatedDetails.IsEarning,
				PANNumber: updatedDetails.PANNumber,
				FullName: updatedDetails.FullName,
				FirstName: updatedDetails.FirstName,
				MiddleName: updatedDetails.MiddleName,
				LastName: updatedDetails.LastName,
				FathersName: updatedDetails.FathersName,
				FathersFirstName: updatedDetails.FathersFirstName,
				FathersMiddleName: updatedDetails.FathersMiddleName,
				FathersLastName: updatedDetails.FathersLastName,
			},
		};
	}

	public Step2DetailsMapper(
		data: InstaApplicationDraft,
		updatedDetails: Step2Details,
	): InstaApplicationDraft 
	{
		return {
			...data,
			Status: ApplicationDraftStatus.STEP2_COMPLETED,
			//@ts-ignore
			Applicant: {
				...data.Applicant,
				Gender: updatedDetails.Gender,
				DateOfBirth: updatedDetails.DateOfBirth,
				CurrentAddressLine1: updatedDetails.CurrentAddressLine1,
				CurrentAddressLine2: updatedDetails.CurrentAddressLine2,
				CurrentAddressCity: updatedDetails.CurrentAddressCity,
				CurrentAddressState: updatedDetails.CurrentAddressState,
				CurrentAddressPincode: updatedDetails.CurrentAddressPincode,
				CurrentAddressCountry: updatedDetails.CurrentAddressCountry,
			},
		};
	}

	public Step3DetailsMapper(
		data: InstaApplicationDraft,
		updatedDetails: Step3Details,
	): InstaApplicationDraft 
	{
		return {
			...data,
			Status: ApplicationDraftStatus.STEP3_COMPLETED,
			//@ts-ignore
			Applicant: {
				...data.Applicant,
				EmploymentType: updatedDetails.EmploymentType,
				Profession: updatedDetails.Profession,
				CompanyName: updatedDetails.CompanyName,
				EarningForYears: updatedDetails.EarningForYears,
				CurrentMonthlyIncome: updatedDetails.CurrentMonthlyIncome,
				EMIsPaying: updatedDetails.EMIsPaying,
			},
		};
	}

	public Step4DetailsMapper(
		data: InstaApplicationDraft,
		updatedDetails: Step4Details,
	): InstaApplicationDraft 
	{
		return {
			...data,
			Status: ApplicationDraftStatus.COMPLETED,
			//@ts-ignore
			Applicant: {
				...data.Applicant,
			},
		};
	}
}

export const mapper = new Mapper();
