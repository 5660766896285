import { apiRequestHandler, getMiddleware } from "../middlewareHelper";
import { actions as types } from "./actions";
import service from "../../services/Application";
import { toast } from "react-toastify";
import { showError } from "../ui/actions";

export const fetchPincodeDetailsFlow = getMiddleware({
	actionType: types.FETCH_PINCODE_DETAILS,
	onRequest: apiRequestHandler(service.getPincodeDetailsForApplication),
});

export const generateUserConsentOtpFlow = getMiddleware({
	actionType: types.GENERATE_USER_CONSENT_OTP,
	onRequest: apiRequestHandler(service.generateUserConsentOTP),
});

export const resendUserConsentOtpFlow = getMiddleware({
	actionType: types.RESEND_USER_CONSENT_OTP,
	onRequest: apiRequestHandler(service.resendUserConsentOTP),
	onSuccess: async () => 
	{
		// toast("Resend SMS Successful!", {
		// 	type: "success",
		// 	position: "top-right",
		// });
	},
});

export const verifyUserConsentOtpFlow = getMiddleware({
	actionType: types.VERIFY_USER_CONSENT_OTP,
	onRequest: apiRequestHandler(service.verifyUserConsentOTP),
});

export const getOrCreateInstaApplicationDraftFlow = getMiddleware({
	actionType: types.GET_OR_CREATE_INSTA_APPLICATION_DRAFT,
	onRequest: apiRequestHandler(service.getOrCreateInstaApplicationDraft),
});

export const updateInstaApplicationDraftFlow = getMiddleware({
	actionType: types.UPDATE_INSTA_APPLICATION_DRAFT,
	onRequest: apiRequestHandler(service.updateInstaApplicationDraft),
	// onSuccess: async () =>
	// {
	// 	toast("Information Updated Successfully", {
	// 		type: "success",
	// 		position: "top-right",
	// 	});
	// },
});

export const fetchPanCardDetailsFlow = getMiddleware({
	actionType: types.FETCH_PANCARD_DETAILS,
	onRequest: apiRequestHandler(service.fetchPanCardDetails),
	onError: async (opts) => 
	{
		toast(opts.error.message, {
			type: "error",
			position: "top-center",
		});
		opts.dispatch(
			showError(
				"field",
				"Pan Error",
				opts.error ? opts.error.message : "Invalid PAN",
				types.FETCH_PANCARD_DETAILS,
				{
					field: "pan",
				},
			),
		);
	},
});

export const createInstaApplicationB2CFlow = getMiddleware({
	actionType: types.CREATE_INSTA_APPLICATION_B2C,
	onRequest: apiRequestHandler(service.createInstaApplicationB2C),
	// onSuccess: async () =>
	// {
	// 	toast("Application Created Successful!", {
	// 		type: "success",
	// 		position: "top-right",
	// 	});
	// },
});

export const sendOrResendInstaApplicantConsentOtpFlow = getMiddleware({
	actionType: types.SEND_OR_RESEND_INSTA_APPLICANT_CONSENT_OTP,
	onRequest: apiRequestHandler(service.sendOrResendInstaApplicantConsentOtp),
	onSuccess: async () => 
	{
		// toast("OTP sent Successfully", {
		// 	type: "success",
		// 	position: "top-right",
		// });
	},
});

export const verifiedInstaApplicantConsentOtpFlow = getMiddleware({
	actionType: types.VERIFY_INSTA_APPLICANT_CONSENT_OTP,
	onRequest: apiRequestHandler(service.verifyInstaApplicantConsentOtp),
	// onSuccess: async () =>
	// {
	// 	toast("OTP Verified!! Application Creation is in progress", {
	// 		type: "success",
	// 		position: "top-right",
	// 	});
	// },
});

export const fetchAddressProofDetailsFlow = getMiddleware({
	actionType: types.FETCH_ADDRESS_PROOF_DETAILS,
	onRequest: apiRequestHandler(service.fetchAddressProofDetails),
});

export const extractAddressProofDetailsFlow = getMiddleware({
	actionType: types.EXTRACT_ADDRESS_PROOF_DETAILS,
	onRequest: apiRequestHandler(service.extractAddressProofDetails),
});

export const reviewAddressProofDetailsFlow = getMiddleware({
	actionType: types.REVIEW_ADDRESS_PROOF_DETAILS,
	onRequest: apiRequestHandler(service.extractAddressProofDetails),
});

export const fetchPhotoIndentityProofDetailsFlow = getMiddleware({
	actionType: types.FETCH_PHOTO_IDENTITY_PROOF_DETAILS,
	onRequest: apiRequestHandler(service.fetchAddressProofDetails),
});

export const extractPhotoIndentityProofDetailsFlow = getMiddleware({
	actionType: types.EXTRACT_PHOTO_IDENTITY_PROOF_DETAILS,
	onRequest: apiRequestHandler(service.extractPhotoIdentityProofDetails),
});

export const reviewPhotoIndentityProofDetailsFlow = getMiddleware({
	actionType: types.REVIEW_PHOTO_IDENTITY_PROOF_DETAILS,
	onRequest: apiRequestHandler(service.extractAddressProofDetails),
});

export const updateKycDetailsFlow = getMiddleware({
	actionType: types.UPDATE_KYC_DETAILS,
	onRequest: apiRequestHandler(service.updateKycDetails),
});

export const addUserVerifiedDetailsFlow = getMiddleware({
	actionType: types.ADD_USER_VERIFIED_ADDRESS,
	onRequest: apiRequestHandler(service.addUserverifiedAddress),
});

export const initInstaKYCFlow = getMiddleware({
	actionType: types.INIT_INSTA_KYC,
	onRequest: apiRequestHandler(service.instaKycInit),
});

export const getInstaKYCResultFlow = getMiddleware({
	actionType: types.INSTA_KYC_RESULT,
	onRequest: apiRequestHandler(service.instaKycResult),
});

export const getInstaDetailsFlow = getMiddleware({
	actionType: types.GET_INSTA_APPLICATION_DETAILS,
	onRequest: apiRequestHandler(service.getInstaDetails),
});

export default [
	fetchPincodeDetailsFlow,
	generateUserConsentOtpFlow,
	resendUserConsentOtpFlow,
	verifyUserConsentOtpFlow,
	getOrCreateInstaApplicationDraftFlow,
	updateInstaApplicationDraftFlow,
	fetchPanCardDetailsFlow,
	sendOrResendInstaApplicantConsentOtpFlow,
	verifiedInstaApplicantConsentOtpFlow,
	createInstaApplicationB2CFlow,
	fetchAddressProofDetailsFlow,
	extractAddressProofDetailsFlow,
	reviewAddressProofDetailsFlow,
	fetchPhotoIndentityProofDetailsFlow,
	extractPhotoIndentityProofDetailsFlow,
	reviewPhotoIndentityProofDetailsFlow,
	addUserVerifiedDetailsFlow,
	updateKycDetailsFlow,
	initInstaKYCFlow,
	getInstaKYCResultFlow,
	getInstaDetailsFlow,
];
