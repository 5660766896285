import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { LoginFormData } from "./models/LoginFormData";
import {
	TextField,
	Button,
	CircularProgress,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import "./style.scss";
import { emailRegx, mobileRegx } from "../../../../utils/commonRegExs";
import { actions } from "store/authentication/actions";
import { checkForErrors } from "utils/helpers";
import { removeDoneActions, removeErrors } from "store/ui/actions";
import { RootState } from "config/store";
import { connect, ConnectedProps } from "react-redux";
import CallUsComponent from "components/CallUsComp";

const mapStateToProps = (state: RootState) => 
{
	const { ui } = state;
	return {
		ui: ui,
		isThereErrorOnSignUp: checkForErrors(
			state.ui.errors,
			actions.USER_SIGNUP,
		),
	};
};

const mapDispatchToProps = {
	removeDoneActions,
	removeErrors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

type Props = ReduxProps & {
    userSignupLoader: any
    onHandleLogin: any
    isUserSignupDone: any
}

const LoginContainer = (props: Props) => 
{
	const { isThereErrorOnSignUp, removeErrors, removeDoneActions, ui } = props;

	useEffect(() => 
	{
		if (isThereErrorOnSignUp) 
		{
			const err = ui.errors[actions.USER_SIGNUP][0];
			const errorMessage = err.description;
			if (err.errType == "field") 
			{
				{
					err.payload.field === "Mobile" &&
                        setError("Mobile", {
                        	message: errorMessage,
                        	types: {
                        		required: err.description,
                        	},
                        	type: "required",
                        });
				}

				{
					err.payload.field === "Email" &&
                        setError("Email", {
                        	message: errorMessage,
                        	types: {
                        		required: err.description,
                        	},
                        	type: "required",
                        });
				}
			}
			removeErrors([actions.USER_SIGNUP]);
		}
	}, [isThereErrorOnSignUp]);

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		setError,
		formState: { errors },
	} = useForm<LoginFormData>({
		mode: "onChange",
	});
	const scrollIntoElement = (name: string) => 
	{
		document.getElementById(name)?.scrollIntoView({
			behavior: "smooth",
			inline: "end",
			block: "start",
		});
	};
	const onSubmitError = (data: any) => 
	{
		const errors = Object.keys(data);
		if (errors.length > 0) 
		{
			scrollIntoElement(data[errors[0]]?.ref?.name);
		}
	};
	const onSubmitUser = (data: any) => 
	{
		//@ts-ignore
		// gtag("event", "conversion", {
		// 	send_to: "AW-10862700578/DuuvCKmu7IUYEKLQ3rso",
		// });
		props.onHandleLogin({
			Name: data.Name,
			Mobile: data.Mobile,
			Email: data.Email,
		});
	};
	return (
		<div className="form-container">
			<Controller
				name={"Name"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Your Name will help us in furture process",
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							label="Name"
							error={!!errors.Name}
							helperText={errors?.Name?.message}
							InputProps={{
								id: "Name",
							}}
						/>
					);
				}}
			/>
			<Controller
				name={"Mobile"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Mobile No is required",
					},
					pattern: {
						value: mobileRegx,
						message: "Invalid Mobile Number!",
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							label="Mobile Number"
							error={!!errors.Mobile}
							helperText={errors?.Mobile?.message}
							InputProps={{
								id: "Mobile",
							}}
						/>
					);
				}}
			/>
			<Controller
				name={"Email"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Email is required",
					},
					pattern: {
						value: emailRegx,
						message: "Invalid Email!",
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							label="Email"
							error={!!errors.Email}
							helperText={errors?.Email?.message}
							InputProps={{
								id: "Email",
							}}
						/>
					);
				}}
			/>
			{/* <FormControlLabel
				control={
					<Checkbox
						disabled
						checked
						inputProps={{ "aria-label": "controlled" }}
					/>
				}
				label={
					<span className="consent__label">
                        I understand the loan is for education-related purpose
                        only
					</span>
				}
			/> */}
			<FormControlLabel
				control={
					<Checkbox
						disabled
						checked
						inputProps={{ "aria-label": "controlled" }}
					/>
				}
				label={
					<span className="consent__label">
                        I give consent to Propelld and/or its lenders to access
                        my Credit Information and confirm that I have understood
                        and agree to comply with the{" "}
						<a
							href="https://www.propelld.com/terms"
							target="_blank"
							style={{ color: "#5476ff" }}
							rel="noreferrer"
						>
                            Terms of Service
						</a>
						{/* {", "} */}
						{/* <a
							href="https://www.propelld.com/privacy"
							target="_blank"
							style={{ color: "#5476ff" }}
							rel="noreferrer"
						>
                            Privacy Policy
						</a>
						{" and "}
						<a
							href="https://www.propelld.com/credit-terms"
							target="_blank"
							style={{ color: "#5476ff" }}
							rel="noreferrer"
						>
                            Credit Information Terms and Conditions.
						</a> */}
					</span>
				}
			/>
			<FormControlLabel
				control={
					<Checkbox
						disabled
						checked
						inputProps={{ "aria-label": "controlled" }}
					/>
				}
				label={
					<span className="consent__label">
                        I authorize Propelld to contact me overriding my
                        registration with the NDNC registry
					</span>
				}
			/>
			<Button
				onClick={handleSubmit(onSubmitUser, onSubmitError)}
				variant="contained"
				color="primary"
				fullWidth
				startIcon={
					props.userSignupLoader ? (
						<CircularProgress
							style={{ color: "white" }}
							size="1rem"
						/>
					) : undefined
				}
				style={{
					backgroundColor: "#5383f9",
					color: "white",
				}}
			>
				{"Let's Go"}
			</Button>
			{/* <CallUsComponent /> */}
		</div>
	);
};

export default connector(LoginContainer);
