import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
	TextField,
	Button,
	FormControl,
	FormLabel,
	RadioGroup,
	Radio,
	FormControlLabel,
	Fab,
	CircularProgress,
} from "@mui/material";
import { toUpper } from "lodash";
import { Step1FormData } from "./models/Step1FormData";
import { panRegx } from "../../../../../utils/commonRegExs";
import { Step1Details } from "../../LogicContainers/StepsLogicContainer";
import {
	checkForDoneAction,
	checkForErrors,
	checkForLoading,
	onSubmitError,
} from "../../../../../utils/helpers";
import {
	actions,
	getOrCreateInstaApplicationDraft,
	updateInstaApplicationDraft,
	fetchPanCardDetails,
} from "../../../../../store/application/actions";
import {
	removeDoneActions,
	removeErrors,
} from "../../../../../store/ui/actions";
import { RootState } from "../../../../../config/store";
import { ConnectedProps, connect } from "react-redux";
import { Result } from "../../index";
import CallUsComponent from "components/CallUsComp";
const mapStateToProps = (state: RootState) => 
{
	const { ui, application } = state;
	return {
		ui: ui,
		applicationDraft:
            application?.userApplication?.Application?.InstaApplicationDraft,
		panLoader: checkForLoading(ui.spinners, actions.FETCH_PANCARD_DETAILS),
		isPanDetailsFetched: checkForDoneAction(
			ui.doneActions,
			actions.FETCH_PANCARD_DETAILS,
		),
		panDetails: application.pancardDetails,
		isThereErrorOnPanFetch: checkForErrors(
			state.ui.errors,
			actions.FETCH_PANCARD_DETAILS,
		),
	};
};

const mapDispatchToProps = {
	getOrCreateInstaApplicationDraft,
	updateInstaApplicationDraft,
	fetchPanCardDetails,
	removeDoneActions,
	removeErrors,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

type Props = ReduxProps & {
    onSaveStep1Details: (data: Step1Details) => void
    onSetStep: (step: number) => void
    isDetailsUpdating: boolean
}
const Step1 = (props: Props) => 
{
	const [selectedLoanAmount, setSelectedLoanAmount] = useState(
		props.applicationDraft?.LoanDetails?.LoanAmount ?? 50000,
	);
	const [selectedTenure, setSelectedTenure] = useState(6);
	const [earningToggle, setEarningToggle] = useState(true);

	const [panError, setPanError] = useState(undefined);

	const {
		ui,
		onSaveStep1Details,
		onSetStep,
		panDetails,
		applicationDraft,
		fetchPanCardDetails,
		isPanDetailsFetched,
		isThereErrorOnPanFetch,
		removeErrors,
		removeDoneActions,
		panLoader,
		isDetailsUpdating,
	} = props;
	// if(!applicationDraft) return null;

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		setError,
		formState: { errors },
	} = useForm<Step1FormData>({
		mode: "onChange",
		defaultValues: {
			EarningIndividual:
                applicationDraft?.Applicant?.IsEarning ?? undefined,
			PanNumber: applicationDraft?.Applicant?.PANNumber ?? "",
			Name: applicationDraft?.Applicant?.FullName ?? "",
			FathersName: applicationDraft?.Applicant?.FathersName ?? "",
			LoanAmount: String(
				applicationDraft?.LoanDetails?.LoanAmount ?? 50000,
			),
			InstituteName: applicationDraft?.LoanDetails?.InstituteName ?? "",
			CourseName: applicationDraft?.LoanDetails?.CourseName ?? "",
		},
	});

	useEffect(() => 
	{
		if (panDetails && isPanDetailsFetched) 
		{
			setValue("Name", panDetails?.PanDetails?.Name);
			setValue("FathersName", panDetails?.PanDetails.FatherName);

			removeDoneActions([actions.FETCH_PANCARD_DETAILS]);
		}
	}, [isPanDetailsFetched, panDetails]);

	useEffect(() => 
	{
		if (isThereErrorOnPanFetch) 
		{
			const err = ui.errors[actions.FETCH_PANCARD_DETAILS][0];
			const errorMessage = err.description || "Invalid PAN number!";
			if (err.errType == "field") 
			{
				setValue("Name", "");
				setValue("FathersName", "");
				setError("PanNumber", {
					message: errorMessage,
					types: {
						required: err.description,
					},
					type: "required",
				});
				setPanError(errorMessage);
			}
			removeErrors([actions.FETCH_PANCARD_DETAILS]);
		}
	}, [isThereErrorOnPanFetch]);

	const onNextSubmit = (data: Step1FormData) => 
	{
		const nameSplit = data.Name?.split(" ");
		const fathersNameSplit = data.FathersName?.split(" ");
		onSaveStep1Details({
			IsEarning: earningToggle,
			PANNumber: data.PanNumber,
			LoanAmount: selectedLoanAmount,
			CourseName: data.CourseName,
			InstituteName: data.InstituteName,
			FullName: data.Name,
			FirstName: nameSplit[0],
			MiddleName: nameSplit.length > 2 ? nameSplit[1] : "",
			LastName: nameSplit[nameSplit.length - 1],
			FathersName: data.FathersName,
			FathersFirstName: fathersNameSplit[0],
			FathersMiddleName:
                fathersNameSplit.length > 2 ? fathersNameSplit[1] : "",
			FathersLastName: fathersNameSplit[nameSplit.length - 1],
		});
	};

	const onPanUpdate = (value: string) => 
	{
		if (/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value) !== true) 
		{
			return;
		}
		if (value.length === 10) 
		{
			fetchPanCardDetails({
				InstaId: String(applicationDraft?.InstaId),
				PanNumber: value,
			});
		}
	};
	return (
		<div className="form-container">
			<FormControl>
				{/* <div className="form--radioGroup">
					<FormLabel id="demo-row-radio-buttons-group-label">
						<div className="label-font">Are You Earning?</div>
					</FormLabel>
					<Controller
						name={"EarningIndividual"}
						control={control}
						render={({ field }) => 
						{
							return (
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
									onChange={field.onChange}
									value={field.value}
								>
									<FormControlLabel
										value={true}
										control={<Radio />}
										label="Yes"
										onClick={() => setEarningToggle(true)}
									/>
									<FormControlLabel
										value={false}
										control={<Radio />}
										label="No"
										onClick={() => setEarningToggle(false)}
									/>
								</RadioGroup>
							);
						}}
					/>
				</div> */}
			</FormControl>
			{earningToggle && (
				<>
					<Controller
						name={"PanNumber"}
						control={control}
						rules={{
							required: {
								value: true,
								message:
                                    "Your PAN will help us in finalizing your loan",
							},
							pattern: {
								value: panRegx,
								message: "Invalid PAN Number!",
							},
							validate: (val) => 
							{
								if (!panDetails) 
								{
									return panError;
								}
								return true;
							},
						}}
						render={({ field }) => 
						{
							return (
								<TextField
									onChange={(e) => 
									{
										setPanError(undefined);
										onPanUpdate(toUpper(e.target.value));
										field.onChange(toUpper(e.target.value));
									}}
									value={field.value}
									variant="outlined"
									label="PAN Number"
									error={!!errors.PanNumber}
									helperText={errors?.PanNumber?.message}
									InputProps={{
										id: "PanNumber",
										endAdornment: panLoader ? (
											<CircularProgress size="1.5rem" />
										) : (
											""
										),
									}}
								/>
							);
						}}
					/>
					{panDetails ||
                    applicationDraft?.Applicant?.FullName ||
                    applicationDraft?.Applicant?.FathersName ? (
							<>
								<Controller
									name={"Name"}
									control={control}
									render={({ field }) => 
									{
										return (
											<TextField
												disabled
												onChange={field.onChange}
												value={field.value}
												variant="outlined"
												label="Name"
											/>
										);
									}}
								/>
								<Controller
									name={"FathersName"}
									control={control}
									rules={{
										required: {
											value: true,
											message: "Please add your Father Name",
										},
									}}
									render={({ field }) => 
									{
										return (
											<TextField
												onChange={field.onChange}
												value={field.value}
												variant="outlined"
												label="Father's Name"
												error={!!errors.FathersName}
												helperText={
													errors?.FathersName?.message
												}
												InputProps={{
													id: "FathersName",
												}}
											/>
										);
									}}
								/>
							</>
						) : null}
					<Controller
						name={"LoanAmount"}
						control={control}
						defaultValue={
							applicationDraft?.LoanDetails?.LoanAmount.toString() ||
                            "50000"
						}
						rules={{
							validate: (val) => 
							{
								if (
									Number(val) < 50000 ||
                                    Number(val) > 1500000
								) 
								{
									return "Amount should be in between 50,000/- to 15,00,000/-";
								}
								return true;
							},
						}}
						render={({ field }) => 
						{
							return (
								<div className="toggle__buttons">
									<p> Loan Amount (in Rupees)</p>
									<div className="loan__amount">
										<div className="first">
											<Fab
												variant="extended"
												className={
													selectedLoanAmount === 50000
														? "active__btn"
														: ""
												}
												onClick={() => 
												{
													if (selectedTenure === 15) 
													{
														setSelectedTenure(6);
													}
													setSelectedLoanAmount(50000);
												}}
											>
                                                50,000/-
											</Fab>
										</div>

										<div className="sec">
											<Fab
												onClick={() => 
												{
													if (selectedTenure === 6) 
													{
														setSelectedTenure(9);
													}
													setSelectedLoanAmount(
														100000,
													);
												}}
												variant="extended"
												className={
													selectedLoanAmount ===
                                                    100000
														? "active__btn"
														: ""
												}
											>
                                                1,00,000/-
											</Fab>
										</div>

										<div className="third">
											<Fab
												variant="extended"
												className={
													selectedLoanAmount ===
                                                    150000
														? "active__btn"
														: ""
												}
												onClick={() => 
												{
													if (selectedTenure === 6) 
													{
														setSelectedTenure(9);
													}
													setSelectedLoanAmount(
														150000,
													);
												}}
											>
                                                1,50,000/-
											</Fab>
										</div>

										<div className="fourth">
											<TextField
												onChange={(e) => 
												{
													setSelectedLoanAmount(
														Number(e.target.value),
													);
													field.onChange(
														e.target.value,
													);
												}}
												value={field.value}
												variant="outlined"
												label="Min: 50000 | Max: 1500000"
												error={!!errors.LoanAmount}
												helperText={
													errors?.LoanAmount?.message
												}
											/>
										</div>
									</div>
								</div>
							);
						}}
					/>
					<Controller
						name={"InstituteName"}
						control={control}
						rules={{
							required: {
								value: true,
								message:
                                    "Please add your Institute Name for which you want to take loan",
							},
						}}
						render={({ field }) => 
						{
							return (
								<TextField
									onChange={field.onChange}
									value={field.value}
									variant="outlined"
									label="Institute Name"
									error={!!errors.InstituteName}
									helperText={errors?.InstituteName?.message}
									InputProps={{
										id: "InstituteName",
									}}
								/>
							);
						}}
					/>
					<Controller
						name={"CourseName"}
						control={control}
						rules={{
							required: {
								value: true,
								message:
                                    "Please add your Course Name for which you want to take loan",
							},
						}}
						render={({ field }) => 
						{
							return (
								<TextField
									onChange={field.onChange}
									value={field.value}
									variant="outlined"
									label="Course Name"
									error={!!errors.CourseName}
									helperText={errors?.CourseName?.message}
									InputProps={{
										id: "CourseName",
									}}
								/>
							);
						}}
					/>

					<Button
						onClick={handleSubmit(onNextSubmit, onSubmitError)}
						variant="contained"
						color="primary"
						fullWidth
						startIcon={
							isDetailsUpdating ? (
								<CircularProgress
									style={{ color: "white" }}
									size="1rem"
								/>
							) : undefined
						}
						style={{
							backgroundColor: "#5383f9",
							color: "white",
						}}
					>
						{"Next Step"}
					</Button>
					{/* <CallUsComponent /> */}
				</>
			)}
			{/* {!earningToggle && (
			// <div className="form--errorBox">
			//     Loan can only be applied by an earning individual. Please
			//     try applying through a different account belonging to an
			//     earning individual.
			// </div>
				<Result
					status="warn"
					title={"OH NO!!!"}
					subTitle={
						"Loan can only be applied by an earning individual. Please try applying through a different account belonging to an earning individual."
					}
				>
					{}
				</Result>
			)} */}
		</div>
	);
};

export default connector(Step1);
