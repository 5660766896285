import React from "react";
import { LeftSideContainer, RightSideContainer } from "../../containers";
import "./style.scss";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "config/store";
import { removeDoneActions } from "store/ui/actions";

const mapStateToProps = (state: RootState) => 
{
	const { authentication } = state;
	return {
		otpSent: authentication.otpSent,
		authorization: authentication.isAuthenticated,
	};
};

const mapDispatchToProps = { removeDoneActions };
const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

const HomePage: React.FC<ReduxProps> = (props) => 
{
	const { otpSent, authorization } = props;

	return (
		<div
			className={`page--container ${
				(authorization || otpSent) && "auth--page--container"
			}`}
		>
			{!otpSent && !authorization && <LeftSideContainer />}
			<RightSideContainer />
		</div>
	);
};

export default connector(HomePage);
