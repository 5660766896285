import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
	TextField,
	Button,
	Alert,
	CircularProgress,
	Skeleton,
} from "@mui/material";
import { Step4FormData } from "./models/Step4FormData";
import {
	checkForDoneAction,
	checkForErrors,
	checkForLoading,
	onSubmitError,
} from "../../../../../utils/helpers";
import {
	actions,
	getOrCreateInstaApplicationDraft,
	updateInstaApplicationDraft,
	fetchPanCardDetails,
	updateKycDetails,
	initInstaKYC,
	updateInstaKYCResult,
	fetchAddressProofDetails,
	getInstaDetails,
} from "../../../../../store/application/actions";
import {
	removeDoneActions,
	removeErrors,
} from "../../../../../store/ui/actions";
import { RootState } from "../../../../../config/store";
import { ConnectedProps, connect } from "react-redux";
import { IdentityTypes } from "types/Type";
import VerifyAddressproofComponent from "../../VerifyAddressproofComponent";
import { mobileRegx } from "utils/commonRegExs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import baseUrl from "config/service";

const mapStateToProps = (state: RootState) => 
{
	const { ui, application } = state;
	return {
		ui: ui,
		isInstaApplicationFetch: checkForDoneAction(
			ui.doneActions,
			actions.GET_OR_CREATE_INSTA_APPLICATION_DRAFT,
		),
		applicationDraft:
            application?.userApplication?.Application?.InstaApplicationDraft,
		createdApplication: application?.userApplication?.Application,
		instaDraft: application?.userApplication?.Application,
		isKYCDetailsUpdated: checkForDoneAction(
			ui.doneActions,
			actions.UPDATE_KYC_DETAILS,
		),
		isInitInstaKYC: checkForDoneAction(
			ui.doneActions,
			actions.INIT_INSTA_KYC,
		),
		updateKYCDetailsLoader: checkForLoading(
			ui.spinners,
			actions.UPDATE_KYC_DETAILS,
		),
		fetchingAddressProofDetailsLoader: checkForLoading(
			ui.spinners,
			actions.FETCH_ADDRESS_PROOF_DETAILS,
		),
		errorWhileFetchingAddressProofDetails: checkForErrors(
			ui.errors,
			actions.FETCH_ADDRESS_PROOF_DETAILS,
		),
		errorWhileUpdateKyc: checkForErrors(
			ui.errors,
			actions.INSTA_KYC_RESULT,
		),
		kycResultLoader: checkForLoading(ui.spinners, actions.INSTA_KYC_RESULT),
		isUpdatedKycResult: checkForDoneAction(
			ui.doneActions,
			actions.INSTA_KYC_RESULT,
		),
		isGettingInstaDetails: checkForLoading(
			ui.spinners,
			actions.GET_INSTA_APPLICATION_DETAILS,
		),
		isUpdatingInstaApplicationDraft: checkForLoading(
			ui.spinners,
			actions.UPDATE_INSTA_APPLICATION_DRAFT,
		),
		instaKYCDetails: application.instaKYCDetails,
		instaKYCResult: application.instaKYCResult,
		addressProofDetails: application.addressProofDetails,
		verifiedAddressProof: application.verifiedAddressProofDetails,
	};
};

const mapDispatchToProps = {
	getOrCreateInstaApplicationDraft,
	updateInstaApplicationDraft,
	fetchPanCardDetails,
	updateKycDetails,
	initInstaKYC,
	updateInstaKYCResult,
	fetchAddressProofDetails,
	getInstaDetails,
	removeDoneActions,
	removeErrors,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

type Props = ReduxProps & {
    onSaveStep4Details: (data: Step4FormData) => void
    onSetStep: (step: number) => void
    isDetailsUpdating: boolean
}
const Step4 = (props: Props) => 
{
	const {
		ui,
		onSaveStep4Details,
		onSetStep,
		applicationDraft,
		removeErrors,
		removeDoneActions,
		updateKycDetails,
		isKYCDetailsUpdated,
		initInstaKYC,
		isInitInstaKYC,
		instaKYCDetails,
		instaKYCResult,
		updateInstaKYCResult,
		fetchAddressProofDetails,
		addressProofDetails,
		errorWhileUpdateKyc,
		updateKYCDetailsLoader,
		fetchingAddressProofDetailsLoader,
		kycResultLoader,
		isUpdatedKycResult,
		getInstaDetails,
		isGettingInstaDetails,
		instaDraft,
		errorWhileFetchingAddressProofDetails,
		verifiedAddressProof,
		isUpdatingInstaApplicationDraft,
		createdApplication,
	} = props;

	const [kycDoneFromUser, setKycDoneFromUser] = useState(false);
	const [addressDetailsFetched, setAddressDetailsFetched] = useState(false);
	const [addressDetailsVerified, setAddressDetailsVerified] = useState(false);

	const { pathname } = useLocation();

	const navigate = useNavigate();

	useEffect(() => 
	{
		const searchParams = new URLSearchParams(window.location.search);

		const transactionId = searchParams.get("state");
		const response = {
			RequestId: searchParams.get("state"),
			Status: searchParams.get("status"),
		};

		if (transactionId) 
		{
			setKycDoneFromUser(true);
			updateInstaKYCResult({
				InstaId: applicationDraft?.InstaId ?? "",
				IsCoBorrower: false,
				Provider: "hyperverge",
				QueryString: window.location.search,
				TransactionId: transactionId ?? "",
				Response: response,
			});
		}
	}, []);

	useEffect(() => 
	{
		if (isKYCDetailsUpdated) 
		{
			removeDoneActions([actions.UPDATE_KYC_DETAILS]);
			initInstaKYC({
				InstaId: applicationDraft?.InstaId ?? "",
				IsCoBorrower: false,
				RedirectionUrl: baseUrl.reDirectionUrl,
			});
		}

		if (isInitInstaKYC) 
		{
			removeDoneActions([actions.INIT_INSTA_KYC]);
			window.location.href = instaKYCDetails.RequestDetails.ConsentLink;
		}
	}, [isKYCDetailsUpdated, isInitInstaKYC]);

	useEffect(() => 
	{
		if (isUpdatedKycResult) 
		{
			if (instaKYCResult.Verified) 
			{
				fetchAddressProofDetails({
					InstaId: applicationDraft?.InstaId ?? "",
					IdentityType: IdentityTypes.AadhaarCard,
					IsCoBorrower: false,
				});
			}
			removeDoneActions([actions.INSTA_KYC_RESULT]);
		}

		if (
			checkForDoneAction(
				ui.doneActions,
				actions.FETCH_ADDRESS_PROOF_DETAILS,
			)
		) 
		{
			setAddressDetailsFetched(true);
			navigate(pathname);
			removeDoneActions([actions.FETCH_ADDRESS_PROOF_DETAILS]);
		}
		if (
			checkForDoneAction(
				ui.doneActions,
				actions.ADD_USER_VERIFIED_ADDRESS,
			)
		) 
		{
			removeDoneActions([actions.ADD_USER_VERIFIED_ADDRESS]);
			setAddressDetailsVerified(true);
			getInstaDetails();
		}
	}, [
		isUpdatedKycResult,
		checkForDoneAction(ui.doneActions, actions.FETCH_ADDRESS_PROOF_DETAILS),
		checkForDoneAction(ui.doneActions, actions.ADD_USER_VERIFIED_ADDRESS),
	]);

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<Step4FormData>({
		mode: "onChange",
		defaultValues: {
			InstaKyc: applicationDraft?.Applicant?.PanCardUploaded ?? false,
			PanCardUploaded:
                applicationDraft?.Applicant?.PanCardUploaded ?? false,
			AadharCardUploaded:
                applicationDraft?.Applicant?.AadharCardUploaded ?? false,
			MobileNumber: applicationDraft?.Applicant?.AadhaarMobile
				? applicationDraft?.Applicant?.AadhaarMobile
				: createdApplication?.Mobile,
		},
	});

	const onProceedKYC = (data: Step4FormData) => 
	{
		updateKycDetails({
			InstaId: applicationDraft?.InstaId ?? "",
			HasCoBorrower: false,
			IsPhoneLinkedToAadhaar: true,
			AadhaarMobile: Number(data.MobileNumber),
		});
	};

	const initialStepOfKycVerification = () => 
	{
		return (
			<>
				<Alert severity="info">
                    Make sure the following number is linked to the Aadhaar!
				</Alert>

				<Controller
					name={"MobileNumber"}
					control={control}
					rules={{
						required: {
							value: true,
							message: "Mobile Number should not be empty!",
						},
						pattern: {
							value: mobileRegx,
							message: "Invalid Mobile!",
						},
					}}
					render={({ field }) => 
					{
						return (
							<TextField
								onChange={field.onChange}
								value={field.value}
								label="Aadhaar Linked Phone Number +91"
								error={!!errors.MobileNumber}
								helperText={errors?.MobileNumber?.message}
								InputProps={{
									id: "MobileNumber",
								}}
							/>
						);
					}}
				/>

				<Button
					onClick={handleSubmit(onProceedKYC, onSubmitError)}
					variant="outlined"
					color="primary"
					fullWidth
					startIcon={
						updateKYCDetailsLoader ? (
							<CircularProgress
								style={{ color: "white" }}
								size="1rem"
							/>
						) : undefined
					}
					style={{
						color: "#ffff",
						backgroundColor: "#5383f9",
					}}
				>
					{errorWhileUpdateKyc ||
                    instaDraft?.EKYC?.ErrorMessage ||
                    errorWhileFetchingAddressProofDetails
						? "Please retry KYC Verification"
						: "Proceed To KYC Verification"}
				</Button>
			</>
		);
	};

	return (
		<div className="form-container">
			{!kycDoneFromUser && (
				<>
					{instaDraft?.EKYC?.Verified && (
						<div
							style={{
								display: "flex",
								justifyItems: "center",
								justifyContent: "space-between",
								padding: "40px",
							}}
						>
							<h3 style={{ marginRight: "30px" }}>EKYC</h3>

							<Button variant="contained" color="success">
                                Verified ☑️
							</Button>
						</div>
					)}

					{!instaDraft?.EKYC?.Verified && (
						<>
							{instaDraft?.EKYC?.ErrorMessage &&
                                instaDraft?.EKYC?.ErrorMessage !== "" && (
								<Alert severity="error">
									{instaDraft?.EKYC?.ErrorMessage}
								</Alert>
							)}
							{initialStepOfKycVerification()}
						</>
					)}
				</>
			)}

			{!verifiedAddressProof && kycDoneFromUser && (
				<>
					{kycResultLoader && (
						<>
							{kycResultLoader && (
								<CircularProgress
									style={{
										color: "5383f9",
										alignSelf: "center",
									}}
									size="4rem"
								/>
							)}

							<h3>
                                Aadhaar Verification Successful.Please do not
                                close this window
							</h3>
						</>
					)}

					{instaKYCResult?.Verified &&
                        fetchingAddressProofDetailsLoader && (
						<>
							{fetchingAddressProofDetailsLoader && (
								<CircularProgress
									style={{
										color: "5383f9",
										alignSelf: "center",
									}}
									size="4rem"
								/>
							)}
							<h3>
                                    Fetching Aadhaar Details...Please do not
                                    close this window
							</h3>
						</>
					)}

					{errorWhileFetchingAddressProofDetails && (
						<>
							<Alert severity="error">
                                Error while Fetching Aadhaar details, please
                                retry KYC!
							</Alert>
							{initialStepOfKycVerification()}
						</>
					)}

					{addressDetailsFetched &&
                        addressProofDetails &&
                        !addressDetailsVerified && (
						<VerifyAddressproofComponent />
					)}
				</>
			)}

			<Button
				onClick={() => 
				{
					props.onSetStep(2);
				}}
				variant="outlined"
				color="primary"
				fullWidth
				style={{
					color: "#5383f9",
				}}
			>
				{"Previous Step"}
			</Button>

			{instaDraft?.EKYC?.Verified && (
				<Button
					onClick={handleSubmit(onSaveStep4Details, onSubmitError)}
					variant="contained"
					color="primary"
					fullWidth
					disabled={!instaDraft?.EKYC?.Verified}
					style={{
						color: "#ffff",
						backgroundColor: "#5383f9",
					}}
					startIcon={
						isUpdatingInstaApplicationDraft ? (
							<CircularProgress
								style={{ color: "white" }}
								size="1rem"
							/>
						) : undefined
					}
				>
					{"Next Step"}
				</Button>
			)}
		</div>
	);
};

export default connector(Step4);
