import { actions as types } from "./actions";
import { Middleware } from "redux";
import { toast } from "react-toastify";
/* eslint-disable no-mixed-spaces-and-tabs */
export const sessionExpiredFlow: Middleware =
    ({ dispatch }) =>
    	(next) =>
    		(action) => 
    		{
    			next(action);
    			if (
    				action.type === types.SESSION_EXPIRED ||
            action.type === types.UNAUTHORIZED_ACCESS
    			) 
    			{
    				dispatch({
    					type: "SESSION_LOGOUT",
    				});
    				toast("Session Expired!", {
    					type: "error",
    					position: "top-right",
    				});
    			}
    		};

export default [sessionExpiredFlow];
