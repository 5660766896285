import { apiRequestHandler, getMiddleware } from "../middlewareHelper";
import { actions as types } from "./actions";
import service from "../../services/Authentication";
import { toast } from "react-toastify";
import { showError } from "store/ui/actions";

export const userSignupFlow = getMiddleware({
	actionType: types.USER_SIGNUP,
	onRequest: apiRequestHandler(service.userSignup),
	onError: async (opts) => 
	{
		let dispatchedError = false;
		if (opts.error?.code === 21) 
		{
			dispatchedError = true;
			opts.dispatch(
				showError(
					"field",
					"Email mobile mismatch error",
					opts.error ? opts.error.message : "Email/Mobile Mismatch",
					types.USER_SIGNUP,
					{
						field: "Email",
					},
				),
			);
		}
		if (opts.error?.code === 13) 
		{
			dispatchedError = true;
			opts.dispatch(
				showError(
					"field",
					"Email Exist",
					opts.error ? opts.error.message : "Email already Exists",
					types.USER_SIGNUP,
					{
						field: "Email",
					},
				),
			);
		}
		if (opts.error?.code === 16) 
		{
			dispatchedError = true;
			opts.dispatch(
				showError(
					"field",
					"OTP error",
					opts.error ? opts.error.message : "Otp generation failed",
					types.USER_SIGNUP,
					{
						field: "Mobile",
					},
				),
			);
		}
		if (!dispatchedError) 
		{
			opts.dispatch(showError("", "", "", types.USER_SIGNUP, {}));
		}
	},
	// onError: async (error) =>
	// {
	// 	const errors = error.error;
	// 	errors.map((ex: any) =>
	// 	{
	// 		toast(ex.Message, {
	// 			type: "error",
	// 			position: "top-right",
	// 		});
	// 	});
	// },
});

export const userMobileOtpVerificationFlow = getMiddleware({
	actionType: types.USER_MOBILE_OTP_VERIFICATION,
	onRequest: apiRequestHandler(service.userLoginWithMobileOtp),
	onError: async (opts) => 
	{
		if (opts.error?.code === 17) 
		{
			opts.dispatch(
				showError(
					"field",
					"Mobile OTP Error",
					opts.error ? opts.error.message : "OTP did not match.",
					types.USER_MOBILE_OTP_VERIFICATION,
					{
						field: "MobileOtp",
					},
				),
			);
		}
	},
	// onError: async (error) =>
	// {
	// 	const errors = error.error;
	// 	errors.map((ex: any) =>
	// 	{
	// 		toast(ex.Message, {
	// 			type: "error",
	// 			position: "top-right",
	// 		});
	// 	});
	// },
});

export const userMobileEmailOtpVerificationFlow = getMiddleware({
	actionType: types.USER_MOBILE_EMAIL_OTP_VERIFICATION,
	onRequest: apiRequestHandler(service.userLoginWithMobileEmailOtp),
	onError: async (opts) => 
	{
		if (opts.error?.code === 19) 
		{
			opts.dispatch(
				showError(
					"field",
					"Mobile OTP Error",
					opts.error ? opts.error.message : "OTP did not match.",
					types.USER_MOBILE_EMAIL_OTP_VERIFICATION,
					{
						field: "MobileOtp",
					},
				),
			);
		}

		if (opts.error?.code === 20) 
		{
			opts.dispatch(
				showError(
					"field",
					"Email OTP Error",
					opts.error ? opts.error.message : "OTP did not match.",
					types.USER_MOBILE_EMAIL_OTP_VERIFICATION,
					{
						field: "EmailOtp",
					},
				),
			);
		}
	},
	// onError: async (error) =>
	// {
	// 	const errors = error.error;
	// 	errors.map((ex: any) =>
	// 	{
	// 		toast(ex.Message, {
	// 			type: "error",
	// 			position: "top-right",
	// 		});
	// 	});
	// },
});

export const resendMobileOtpFlow = getMiddleware({
	actionType: types.RESEND_MOBILE_OTP,
	onRequest: apiRequestHandler(service.resendMobileOtp),
	onSuccess: async () => 
	{
		toast("OTP Resend Successfully", {
			type: "success",
			position: "top-right",
		});
	},
	onError: async (error) => 
	{
		const errors = error.error;

		toast(errors.Message, {
			type: "error",
			position: "top-right",
		});
	},
});
/* eslint-disable  @typescript-eslint/no-explicit-any */

export const resendEmailOtpFlow = getMiddleware({
	actionType: types.RESEND_EMAIL_OTP,
	onRequest: apiRequestHandler(service.resendEmailOtp),
	onSuccess: async () => 
	{
		toast("OTP Resend Successfully", {
			type: "success",
			position: "top-right",
		});
	},
	onError: async (error) => 
	{
		const errors = error.error;
		errors.map((ex: any) => 
		{
			toast(ex.Message, {
				type: "error",
				position: "top-right",
			});
		});
	},
});

export const getWebflowDetailsFlow = getMiddleware({
	actionType: types.GET_WEBFLOW_DETAILS,
	onRequest: apiRequestHandler(service.fetchWebflowDetails),
	onError: async (error) => 
	{
		toast(error.error.message, {
			type: "error",
			position: "top-right",
		});
		error.dispatch(showError("", "", "", types.GET_WEBFLOW_DETAILS, {}));
	},
});

export default [
	userSignupFlow,
	userMobileOtpVerificationFlow,
	userMobileEmailOtpVerificationFlow,
	resendMobileOtpFlow,
	resendEmailOtpFlow,
	getWebflowDetailsFlow,
];
