export const DocumentTypes = {
	PhotoIdentityProof: 1,
	AddressProof: 2,
	IncomeProof: 3,
	SignatureProof: 4,
	AcademicCertificates: 5,
	BankStatementProof: 6,
	Miscellaneous: 7,
};

export const IdentityTypes = {
	PanCard: 1,
	AadhaarCard: 2,
	RationCard: 3,
	Passport: 4,
	DrivingLicense: 5,
	BankEStatement: 6,
	ElectricityBill: 7,
	Passbook: 8,
	ITR: 9,
	SalarySlip: 10,
	RegistrationCertificateOfBusinessforSelfEmployed: 11,
	VotersIdentityCard: 12,
	IncomeShareAgreement: 13,
	MarksList: 38,
};
