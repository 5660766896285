import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
	TextField,
	Button,
	FormControl,
	FormLabel,
	RadioGroup,
	Radio,
	FormControlLabel,
	Fab,
	CircularProgress,
} from "@mui/material";
import { Step3FormData } from "./models/Step3FormData";
import { Step3Details } from "../../LogicContainers/StepsLogicContainer";
import {
	checkForDoneAction,
	checkForLoading,
	onSubmitError,
} from "../../../../../utils/helpers";
import {
	removeDoneActions,
	removeErrors,
} from "../../../../../store/ui/actions";
import {
	actions,
	getOrCreateInstaApplicationDraft,
	updateInstaApplicationDraft,
	updateKycDetails,
} from "../../../../../store/application/actions";
import { RootState } from "../../../../../config/store";
import { ConnectedProps, connect } from "react-redux";
import CallUsComponent from "components/CallUsComp";
const mapStateToProps = (state: RootState) => 
{
	const { ui, application } = state;

	return {
		ui: ui,
		applicationDraft:
            application?.userApplication?.Application?.InstaApplicationDraft,
	};
};

const mapDispatchToProps = {
	getOrCreateInstaApplicationDraft,
	updateInstaApplicationDraft,
	updateKycDetails,
	removeDoneActions,
	removeErrors,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

type Props = ReduxProps & {
    onSaveStep3Details: (data: Step3Details) => void
    onSetStep: (step: number) => void
    isDetailsUpdating: boolean
}
export const Step3 = (props: Props) => 
{
	const [selectedEarningPeriod, setSelectedEarningPeriod] = useState(0.4);
	const {
		ui,
		onSaveStep3Details,
		onSetStep,
		applicationDraft,
		removeErrors,
		removeDoneActions,
		isDetailsUpdating,
	} = props;
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors },
	} = useForm<Step3FormData>({
		mode: "onChange",
		defaultValues: {
			EmploymentType: applicationDraft?.Applicant?.EmploymentType ?? "",
			Profession: applicationDraft?.Applicant?.Profession ?? "",
			BusinessName: applicationDraft?.Applicant?.CompanyName ?? "",
			EarningType: selectedEarningPeriod ?? 0,
			CurrentIncome:
                applicationDraft?.Applicant?.CurrentMonthlyIncome ?? 0,
			CurrentEmi: applicationDraft?.Applicant?.EMIsPaying ?? 0,
		},
	});

	const onNextSubmit = (data: Step3FormData) => 
	{
		if (applicationDraft) 
		{
			updateKycDetails({
				InstaId: applicationDraft?.InstaId,
				HasCoBorrower: false,
				IsPhoneLinkedToAadhaar: false,
				AadhaarMobile: null,
			});
		}
		onSaveStep3Details({
			EmploymentType: data.EmploymentType,
			Profession: data.Profession,
			CompanyName: data.BusinessName,
			EarningForYears: data.EarningType,
			CurrentMonthlyIncome: data.CurrentIncome,
			EMIsPaying: data.CurrentEmi,
		});
	};

	return (
		<div className="form-container">
			<FormControl>
				<div className="form--radioGroup form--radioGroup-box">
					<FormLabel id="demo-row-radio-buttons-group-label">
                        I am?
					</FormLabel>
					<Controller
						name={"EmploymentType"}
						control={control}
						rules={{
							required: {
								value: true,
								message: "Please add your Earning Type",
							},
						}}
						render={({ field }) => 
						{
							return (
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
									onChange={field.onChange}
									value={field.value}
								>
									<FormControlLabel
										value="Salaried"
										control={<Radio />}
										label="Salaried"
									/>
									<FormControlLabel
										value="Self-Employed"
										control={<Radio />}
										label="Self-Employed"
									/>
								</RadioGroup>
							);
						}}
					/>
				</div>
			</FormControl>
			<Controller
				name={"Profession"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Please add your Profession",
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							variant="outlined"
							label="Profession"
							placeholder="My Profession is:"
							error={!!errors.Profession}
							helperText={errors?.Profession?.message}
							InputProps={{
								id: "Profession",
							}}
						/>
					);
				}}
			/>
			<Controller
				name={"BusinessName"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Please add your Company/Business Name",
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							variant="outlined"
							label="Company/Business Name"
							placeholder="My Company/Business Name is:"
							error={!!errors.BusinessName}
							helperText={errors?.BusinessName?.message}
							InputProps={{
								id: "BusinessName",
							}}
						/>
					);
				}}
			/>
			<Controller
				name={"EarningType"}
				control={control}
				// defaultValue={"< 6 month"}
				render={({ field }) => 
				{
					return (
						<div className="toggle__buttons">
							<p> I have been earning for</p>
							<div className="loan__amount">
								<Fab
									variant="extended"
									className={
										selectedEarningPeriod === 0.4
											? "active__btn"
											: ""
									}
									onClick={() => 
									{
										setSelectedEarningPeriod(0.4);
										field.onChange(0.4);
									}}
								>
									{"< 6 month"}
								</Fab>
								<Fab
									onClick={() => 
									{
										setSelectedEarningPeriod(0.8);

										field.onChange(0.8);
									}}
									variant="extended"
									className={
										selectedEarningPeriod === 0.8
											? "active__btn"
											: ""
									}
								>
									{"6 - 12 month"}
								</Fab>
								<Fab
									variant="extended"
									className={
										selectedEarningPeriod === 2
											? "active__btn"
											: ""
									}
									onClick={() => 
									{
										setSelectedEarningPeriod(2);
										field.onChange(2);
									}}
								>
									{"1 - 3 years"}
								</Fab>
								<Fab
									variant="extended"
									className={
										selectedEarningPeriod === 3.5
											? "active__btn"
											: ""
									}
									onClick={() => 
									{
										setSelectedEarningPeriod(3.5);

										field.onChange(3.5);
									}}
								>
									{"> 3 years"}
								</Fab>
							</div>
						</div>
					);
				}}
			/>

			<Controller
				name={"CurrentIncome"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Please add Current Income",
					},
					validate: (val) => 
					{
						if (Number(val) < 10000) 
						{
							return "Income is not sufficient to proceed";
						}

						return true;
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							variant="outlined"
							label="Current Income"
							placeholder="My current monthly income is"
							error={!!errors.CurrentIncome}
							helperText={errors?.CurrentIncome?.message}
							InputProps={{
								id: "CurrentIncome",
							}}
						/>
					);
				}}
			/>
			<div className="form--disclaimer">
                * Please note that this will be verified from your Bank
                Statement. Discrepancy in the declared income can result in
                rejection.
			</div>
			<Controller
				name={"CurrentEmi"}
				control={control}
				rules={{
					required: {
						value: true,
						message: "Please add Current EMI",
					},
				}}
				render={({ field }) => 
				{
					return (
						<TextField
							onChange={field.onChange}
							value={field.value}
							variant="outlined"
							label="Current Emi"
							placeholder="I am current paying total monthly EMIs of Rupees"
							error={!!errors.CurrentEmi}
							helperText={errors?.CurrentEmi?.message}
							InputProps={{
								id: "CurrentEmi",
							}}
						/>
					);
				}}
			/>
			<div className="form--disclaimer">
                * Please note that this will be verified from your Central
                Bureau Report and Bank Statement. Consider all loan EMIs but NOT
                credit card bills and Current Propelld Loan.
			</div>
			<Button
				onClick={handleSubmit(onNextSubmit, onSubmitError)}
				variant="outlined"
				color="primary"
				fullWidth
				startIcon={
					isDetailsUpdating ? (
						<CircularProgress
							style={{ color: "white" }}
							size="1rem"
						/>
					) : undefined
				}
				style={{
					backgroundColor: "#5383f9",
					color: "white",
				}}
			>
				{"Next Step"}
			</Button>
			<Button
				onClick={() => 
				{
					props.onSetStep(1);
					// handleSubmit(onVerifyOtp, onSubmitError);
				}}
				variant="outlined"
				color="primary"
				fullWidth
				// startIcon={
				// 	props.verifyOTPLoader ? (
				// 		<CircularProgresss
				// 			style={{ color: "white" }}
				// 			size="1rem"
				// 		/>
				// 	) : undefined
				// }
				style={{
					color: "#5383f9",
				}}
			>
				{"Previous Step"}
			</Button>
			{/* <CallUsComponent /> */}
		</div>
	);
};

export default connector(Step3);
