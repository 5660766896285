import React, { useEffect, useState } from "react";
import { CustomizedSteppers } from "./StepperComponent";
import { Step1, Step2, Step3, StepCompleted, Step4, Step5 } from "../../Steps";
import "./style.scss";
import { RootState } from "../../../../../config/store";
import {
	checkForDoneAction,
	checkForLoading,
} from "../../../../../utils/helpers";
import {
	actions,
	getOrCreateInstaApplicationDraft,
	updateInstaApplicationDraft,
} from "../../../../../store/application/actions";
import { resendMobileOtp } from "../../../../../store/authentication/actions";
import { ConnectedProps, connect } from "react-redux";
import { Skeleton } from "@mui/material";
import { ApplicationDraftStatus, mapper } from "./mapper";
import { removeDoneActions } from "store/ui/actions";
import { Result } from "../../index";
import { forEach } from "lodash";
import { EligibilityResponses } from "../../Steps/Step5Container/models/EligibilityResponses";

const mapStateToProps = (state: RootState) => 
{
	const { ui, application, authentication } = state;
	return {
		ui: ui,
		userApplication: application.userApplication,
		isFetchingUserApplication:
            checkForLoading(
            	ui.spinners,
            	actions.GET_OR_CREATE_INSTA_APPLICATION_DRAFT,
            ) ||
            checkForLoading(ui.spinners, actions.GET_INSTA_APPLICATION_DETAILS),
		isUserApplicationFetch: checkForDoneAction(
			ui.doneActions,
			actions.GET_OR_CREATE_INSTA_APPLICATION_DRAFT,
		),
		userMobile: authentication.userMobile,
		isDetailsUpdating: checkForLoading(
			ui.spinners,
			actions.UPDATE_INSTA_APPLICATION_DRAFT,
		),
		isDetailsUpdated: checkForDoneAction(
			ui.doneActions,
			actions.UPDATE_INSTA_APPLICATION_DRAFT,
		),
		creditEligibilityCode:
            application?.userApplication?.Application?.CreditEligibilityCode,
	};
};

const mapDispatchToProps = {
	getOrCreateInstaApplicationDraft,
	updateInstaApplicationDraft,
	resendMobileOtp,
	removeDoneActions,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

interface Props extends ReduxProps {
    step: any
    setStepCount: any
    setStepByInstaDraftStatus: any
}

export type Step1Details = {
    LoanAmount: number
    CourseName: string
    InstituteName: string
    IsEarning: boolean
    PANNumber: string
    FullName: string
    FirstName: string
    MiddleName: string
    LastName: string
    FathersName: string
    FathersFirstName: string
    FathersMiddleName: string
    FathersLastName: string
}

export type Step2Details = {
    Gender: string
    DateOfBirth: string
    CurrentAddressLine1: string
    CurrentAddressLine2: string
    CurrentAddressCity: string
    CurrentAddressState: string
    CurrentAddressPincode: number
    CurrentAddressCountry: string
}

export type Step3Details = {
    EmploymentType: string
    Profession: string
    CompanyName: string
    EarningForYears: number
    CurrentMonthlyIncome: number
    EMIsPaying: number
}

export type Step4Details = {
    InstaKyc: boolean
    PanCardUploaded: boolean
    AadharCardUploaded: boolean
}

const StepsLogicContainer = (props: Props) => 
{
	const {
		isFetchingUserApplication,
		resendMobileOtp,
		userApplication,
		updateInstaApplicationDraft,
		isUserApplicationFetch,
		ui,
		isDetailsUpdating,
		isDetailsUpdated,
		removeDoneActions,
		creditEligibilityCode,
		setStepByInstaDraftStatus,
		step,
		setStepCount,
	} = props;

	const instaDraft = userApplication?.Application?.InstaApplicationDraft;

	useEffect(() => 
	{
		if (ui.doneActions[actions.GET_OR_CREATE_INSTA_APPLICATION_DRAFT]) 
		{
			triggerPixelEvents(instaDraft?.Status ?? "");
			if (userApplication?.IncorrectPan) 
			{
				setStepByInstaDraftStatus("STARTED");
			}
			else 
			{
				setStepByInstaDraftStatus(instaDraft?.Status ?? "");
			}
			removeDoneActions([actions.GET_OR_CREATE_INSTA_APPLICATION_DRAFT]);
		}
	}, [ui.doneActions[actions.GET_OR_CREATE_INSTA_APPLICATION_DRAFT]]);

	useEffect(() => 
	{
		if (isDetailsUpdated && instaDraft) 
		{
			setStepByInstaDraftStatus(instaDraft?.Status);
			triggerPixelEvents(instaDraft?.Status);
			removeDoneActions([actions.UPDATE_INSTA_APPLICATION_DRAFT]);
		}
	}, [isDetailsUpdated]);

	const triggerPixelEvents = (status: string) => 
	{
		switch (status) 
		{
		case ApplicationDraftStatus.STEP1_COMPLETED:
			//@ts-ignore
			fbq("track", "BasicInfo2");
			return;
		case ApplicationDraftStatus.STEP2_COMPLETED:
			//@ts-ignore
			fbq("track", "PersonalDetails");
			return;
		case ApplicationDraftStatus.STEP3_COMPLETED: {
			//@ts-ignore
			fbq("track", "EarningDetails");
			if (
				userApplication?.Application?.DecisionEnginePixelNames &&
                    userApplication?.Application?.DecisionEnginePixelNames
                    	.length > 0
			) 
			{
				forEach(
					userApplication?.Application?.DecisionEnginePixelNames,
					(pixel) => 
					{
						//@ts-ignore
						fbq("trackCustom", pixel);
					},
				);
			}
			const monthlyIncome =
                    instaDraft?.Applicant?.CurrentMonthlyIncome ?? 0;

			if (userApplication?.CreditLineApprovalForB2C) 
			{
				//@ts-ignore
				fbq("trackCustom", "CreditLineApprove");
			}
			switch (creditEligibilityCode) 
			{
			case EligibilityResponses.PRE_APPROVED:
				//@ts-ignore
				fbq("track", "PreApproved");

				if (monthlyIncome <= 20000) 
				{
					//@ts-ignore
					fbq("trackCustom", "PreApprovedBelow20k");
				}
				else if (monthlyIncome > 200000) 
				{
					//@ts-ignore
					fbq("trackCustom", "PreApprovedAbove2lac");
				}
				else if (monthlyIncome > 100000) 
				{
					//@ts-ignore
					fbq("trackCustom", "PreApprovedAbove1lac");
				}
				else if (monthlyIncome > 50000) 
				{
					//@ts-ignore
					fbq("trackCustom", "PreApprovedAbove50k");
				}
				else if (monthlyIncome > 20000) 
				{
					//@ts-ignore
					fbq("trackCustom", "PreApprovedAbove20k");
				}
				break;
			case EligibilityResponses.PRE_QUALIFIED:
				//@ts-ignore
				fbq("track", "PreQualified");
				break;
			case EligibilityResponses.MANUAL_APPROVED:
				//@ts-ignore
				fbq("track", "MannualApproved");
				break;
			}
			return;
		}
		}
	};

	const onSaveStep1Details = (data: Step1Details) => 
	{
		if (instaDraft) 
		{
			const mappedInstaApplicationDraft = mapper.Step1DetailsMapper(
				instaDraft,
				data,
			);
			updateInstaApplicationDraft(mappedInstaApplicationDraft);
		}
	};

	const onSaveStep2Details = (data: Step2Details) => 
	{
		if (instaDraft) 
		{
			const mappedInstaApplicationDraft = mapper.Step2DetailsMapper(
				instaDraft,
				data,
			);
			updateInstaApplicationDraft(mappedInstaApplicationDraft);
		}
	};

	const onSaveStep3Details = (data: Step3Details) => 
	{
		if (instaDraft) 
		{
			const mappedInstaApplicationDraft = mapper.Step3DetailsMapper(
				instaDraft,
				data,
			);
			updateInstaApplicationDraft(mappedInstaApplicationDraft);
		}
	};

	const onSaveStep4Details = (data: Step4Details) => 
	{
		if (instaDraft) 
		{
			const mappedInstaApplicationDraft = mapper.Step4DetailsMapper(
				instaDraft,
				data,
			);
			//@ts-ignore
			fbq("track", "DocumentsUploaded");
			updateInstaApplicationDraft(mappedInstaApplicationDraft);
		}
	};

	const handleResendMobileOtp = (value: any) => 
	{
		const { userMobile } = props;
		resendMobileOtp({
			mobile: userMobile,
		});
	};

	const stepSelector = (currentStep: number) => 
	{
		switch (currentStep) 
		{
		case 0:
			return (
				<Step1
					onSetStep={setStepCount}
					onSaveStep1Details={onSaveStep1Details}
					isDetailsUpdating={isDetailsUpdating}
				/>
			);
		case 1:
			return (
				<Step2
					onSetStep={setStepCount}
					onSaveStep2Details={onSaveStep2Details}
					isDetailsUpdating={isDetailsUpdating}
				/>
			);
		case 2:
			return (
				<Step3
					onSetStep={setStepCount}
					onSaveStep3Details={onSaveStep3Details}
					isDetailsUpdating={isDetailsUpdating}
				/>
			);
		case 3:
			return (
				<Step4
					onSetStep={setStepCount}
					onSaveStep4Details={onSaveStep4Details}
					isDetailsUpdating={isDetailsUpdating}
				/>
			);
		case 4:
			return (
				<Step5
					onSetStep={setStepCount}
					onResendMobileOtp={handleResendMobileOtp}
					mobileNo={props.userMobile}
				/>
			);
		default:
			return (
				<Step1
					onSetStep={setStepCount}
					onSaveStep1Details={onSaveStep1Details}
					isDetailsUpdating={isDetailsUpdating}
				/>
			);
		}
	};
	return (
		<div>
			{isFetchingUserApplication && (
				<>
					<Skeleton />
					<Skeleton animation="wave" />
					<Skeleton animation={false} />
				</>
			)}
			{!isFetchingUserApplication && userApplication?.ContactAgent && (
			// <div className="form--errorBox">
			//     Your application is already in progress, please connect with
			//     our agents on{" "}
			// 	<span>
			// 		<a href="www.propelld.com">www.propelld.com</a>
			// 	</span>
			// </div>
				<Result
					status="success"
					title={"Hurray!!!"}
					subTitle={
						"Your application is already in progress, please connect with our agents on"
					}
				>
					<a href="www.propelld.com">www.propelld.com</a>
				</Result>
			)}

			{!isFetchingUserApplication && !userApplication?.ContactAgent && (
				<>
					<CustomizedSteppers step={step} />
					<div className="stepForm">{stepSelector(step)}</div>
				</>
			)}
		</div>
	);
};

export default connector(StepsLogicContainer);
