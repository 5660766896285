export const checkForLoading = (data: any, actionType: string) => 
{
	return data[actionType];
};

export const checkForDoneAction = (data: any, actionType: string) => 
{
	return data[actionType];
};

export const checkForErrors = (data: any, actionType: string) => 
{
	return data[actionType];
};

export const formatWithCurrency = (
	value: number | string | undefined,
	prefix = "₹",
) => 
{
	return prefix + " " + (value || "0");
};

const scrollIntoElement = (name: string) => 
{
	document.getElementById(name)?.scrollIntoView({
		behavior: "smooth",
		inline: "end",
		block: "start",
	});
};

/**
 *
 * @param data To highlight the field errors while filling the form
 */
export const onSubmitError = (data: any) => 
{
	const errors = Object.keys(data);
	if (errors.length > 0) 
	{
		scrollIntoElement(data[errors[0]]?.ref?.name);
	}
};
