import React from "react";
import "./style.scss";
import { PropelldLogo } from "../../assets";
import bgImage from "../../assets/bg.png";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
	page_left: {
		background:
            "linear-gradient(204.7deg, #769BFF -8.88%, #4162FF 107.77%);",

		backgroundImage: `url(${bgImage})`,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		padding: "24px",
		[theme.breakpoints.up("lg")]: {
			margin: "24px",
			padding: "36px 64px 0px",
			borderRadius: "12px",
			height: "90vh",
			width: "80vh",
		},
	},
}));

const LeftSideContainer = () => 
{
	const classes = useStyles();
	return (
		<div className="main">
			<div className={classes.page_left}>
				<div className={"content__main"}>
					<div>
						<PropelldLogo />
					</div>
					<div>
						<p className="title">
							<span className="title1">Low-Interest</span>
							<br />
							<span className="title2">Student Loans</span>
						</p>
						<ul>
							<li>Loan without collateral</li>
							<li>Instant loan for students</li>
							<li>No physical verification</li>
							<li>Higher chances of approval</li>
						</ul>
					</div>
					<div className="footer">
                        On our journey to democratizing access to education,
                        we’ve helped 1.5 Lakhs+ students and parents.
					</div>
				</div>
			</div>
		</div>
	);
};

export default LeftSideContainer;
