import { createReducer } from "@reduxjs/toolkit";
import { actions as types } from "./actions";
import { GetPincodeDetailsResponse } from "../../services/Application/models/GetPincodeDetailsResponse";
import { actionRequest, actionSuccess } from "../helper";
import { GenerateUserConsentOTPResponse } from "../../services/Application/models/GenerateUserConsent";
import { VerifyUserConsentOTPResponse } from "../../services/Application/models/VerifyUserConsent";
import { GetOrCreateApplicationDraftResponse } from "../../services/Application/models/GetOrCreateApplicationDraftResponse";
import { GetPanCardDetailsResponse } from "../../services/Application/models/GetPanCardDetailsResponse";
// import { AddressProofMapper } from "../../Mappers/AddressProofMapper";
export interface State {
    pincodeDetails: GetPincodeDetailsResponse | null
    generatedUserRequest: GenerateUserConsentOTPResponse | null
    eligibilityResult: VerifyUserConsentOTPResponse | null
    userApplication: GetOrCreateApplicationDraftResponse | null
    pancardDetails: GetPanCardDetailsResponse | null
    instaApplicationCreated: boolean
    creditEligibilityCode: number | null
    addressProofDetails: any | null
    addressProofDetailsUploaded: boolean
    photoIdentityProofDetailsUploaded: boolean
    instaKYCDetails: any | null
    instaKYCResult: any | null
    verifiedAddressProofDetails: boolean
}

const initialState: State = {
	pincodeDetails: null,
	generatedUserRequest: null,
	eligibilityResult: null,
	userApplication: null,
	pancardDetails: null,
	instaApplicationCreated: false,
	creditEligibilityCode: null,
	addressProofDetails: null,
	addressProofDetailsUploaded: false,
	photoIdentityProofDetailsUploaded: false,
	instaKYCDetails: null,
	instaKYCResult: null,
	verifiedAddressProofDetails: false,
};

export default createReducer(initialState, {
	[actionRequest(types.FETCH_PINCODE_DETAILS)]: (state) => 
	{
		state.pincodeDetails = null;
	},
	[actionSuccess(types.FETCH_PINCODE_DETAILS)]: (state, action) => 
	{
		state.pincodeDetails = action.payload;
	},

	[actionSuccess(types.GENERATE_USER_CONSENT_OTP)]: (state, action) => 
	{
		state.generatedUserRequest = action.payload;
	},

	[actionSuccess(types.VERIFY_USER_CONSENT_OTP)]: (state, action) => 
	{
		state.eligibilityResult = action.payload;
	},

	[actionSuccess(types.GET_OR_CREATE_INSTA_APPLICATION_DRAFT)]: (
		state,
		action,
	) => 
	{
		state.userApplication = action.payload;
	},

	[actionSuccess(types.UPDATE_INSTA_APPLICATION_DRAFT)]: (state, action) => 
	{
		//@ts-ignore
		state.userApplication = {
			...state.userApplication,
			Application: action.payload,
		};
	},

	[actionRequest(types.FETCH_PANCARD_DETAILS)]: (state) => 
	{
		state.pancardDetails = null;
	},

	[actionSuccess(types.FETCH_PANCARD_DETAILS)]: (state, action) => 
	{
		state.pancardDetails = action.payload;
	},

	[actionRequest(types.CREATE_INSTA_APPLICATION_B2C)]: (state) => 
	{
		state.creditEligibilityCode = null;
	},

	[actionSuccess(types.CREATE_INSTA_APPLICATION_B2C)]: (state, action) => 
	{
		state.instaApplicationCreated = true;
		state.creditEligibilityCode = action.payload.CreditEligibilityCode;
	},

	[actionSuccess(types.FETCH_ADDRESS_PROOF_DETAILS)]: (state, action) => 
	{
		//@ts-ignore
		state.userApplication = {
			...state.userApplication,
			// AddressProofDetails: AddressProofMapper(action.payload.Extraction),
		};
		state.addressProofDetailsUploaded = action.payload;
		state.addressProofDetails = action.payload;
	},

	[actionSuccess(types.EXTRACT_ADDRESS_PROOF_DETAILS)]: (state, action) => 
	{
		//@ts-ignore
		state.userApplication = {
			...state.userApplication,
			AddressProofDetailsUploaded: action.payload.Extracted,
		};
	},
	[actionSuccess(types.EXTRACT_PHOTO_IDENTITY_PROOF_DETAILS)]: (
		state,
		action,
	) => 
	{
		//@ts-ignore
		state.userApplication = {
			...state.userApplication,
			PhotoProofDetailsUploaded: action.payload.Extracted,
		};
		state.photoIdentityProofDetailsUploaded = action.payload.Extracted;
	},
	[actionSuccess(types.ADD_USER_VERIFIED_ADDRESS)]: (state, action) => 
	{
		//@ts-ignore
		state.addressProofDetailsUploaded = action.payload.Done;
		state.verifiedAddressProofDetails = true;
	},
	[actionSuccess(types.INIT_INSTA_KYC)]: (state, action) => 
	{
		state.instaKYCDetails = action.payload;
	},
	[actionSuccess(types.INSTA_KYC_RESULT)]: (state, action) => 
	{
		state.instaKYCResult = action.payload;
	},
	[actionSuccess(types.GET_INSTA_APPLICATION_DETAILS)]: (state, action) => 
	{
		//@ts-ignore
		state.userApplication = {
			...state.userApplication,
			Application: action.payload.Application,
		};
	},
});
