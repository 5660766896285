import { createAction } from "@reduxjs/toolkit";
import {
	UserSignupRequest,
	UserLoginMobileEmailOtp,
	UserLoginMobileOtp,
	ResendEmailOtpRequest,
	ResendMobileOtpRequest,
} from "../../services/Authentication/models/UserSignupRequest";
import { actionRequest } from "../helper";

export const actions = {
	USER_SIGNUP: "USER_SIGNUP",
	USER_MOBILE_OTP_VERIFICATION: "USER_MOBILE_OTP_VERIFICATION",
	USER_MOBILE_EMAIL_OTP_VERIFICATION: "USER_MOBILE_EMAIL_OTP_VERIFICATION",
	RESEND_MOBILE_OTP: "RESEND_MOBILE_OTP",
	RESEND_EMAIL_OTP: "RESEND_EMAIL_OTP",
	SESSION_LOGOUT: "SESSION_LOGOUT",
	GET_WEBFLOW_DETAILS: "GET_WEBFLOW_DETAILS",
};

export const userSignup = createAction<UserSignupRequest>(
	actionRequest(actions.USER_SIGNUP),
);

export const userMobileOtpVerification = createAction<UserLoginMobileOtp>(
	actionRequest(actions.USER_MOBILE_OTP_VERIFICATION),
);
/* eslint-disable no-mixed-spaces-and-tabs */
export const userMobileEmailOtpVerification =
    createAction<UserLoginMobileEmailOtp>(
    	actionRequest(actions.USER_MOBILE_EMAIL_OTP_VERIFICATION),
    );

export const resendMobileOtp = createAction<ResendMobileOtpRequest>(
	actionRequest(actions.RESEND_MOBILE_OTP),
);

export const resendEmailOtp = createAction<ResendEmailOtpRequest>(
	actionRequest(actions.RESEND_EMAIL_OTP),
);

export const getWebflowDetails = createAction<string>(
	actionRequest(actions.GET_WEBFLOW_DETAILS),
);
