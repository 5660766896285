import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { EligibilityResponses } from "./models/EligibilityResponses";
import { RootState } from "../../../../../config/store";
import { connect, ConnectedProps } from "react-redux";
import {
	checkForDoneAction,
	checkForLoading,
} from "../../../../../utils/helpers";
import {
	actions,
	createInstaApplicationB2C,
	sendOrResendInstaApplicantConsentOtp,
	verifyInstaApplicantConsentOtp,
	getOrCreateInstaApplicationDraft,
} from "../../../../../store/application/actions";
import { removeDoneActions } from "../../../../../store/ui/actions";
import { Result } from "../../index";
import { EligibilityCodes } from "pages/home/Main/helper";
import {
	ApplicationDraftStatus,
	mapper,
} from "../../LogicContainers/StepsLogicContainer/mapper";
const mapStateToProps = (state: RootState) => 
{
	const { ui, application } = state;
	return {
		isUserInstaCreating: checkForLoading(
			ui.spinners,
			actions.CREATE_INSTA_APPLICATION_B2C,
		),
		isUserInstaCompleted: checkForDoneAction(
			ui.doneActions,
			actions.CREATE_INSTA_APPLICATION_B2C,
		),
		creditEligibilityCode:
            application?.userApplication?.Application?.CreditEligibilityCode,
		userApplication: application?.userApplication,
		applicationDraft:
            application?.userApplication?.Application?.InstaApplicationDraft,
		sendOrResendOTPLoader: checkForLoading(
			ui.spinners,
			actions.SEND_OR_RESEND_INSTA_APPLICANT_CONSENT_OTP,
		),
		otpVerifyLoader: checkForLoading(
			ui.spinners,
			actions.VERIFY_INSTA_APPLICANT_CONSENT_OTP,
		),
		isOTPSent: checkForDoneAction(
			ui.doneActions,
			actions.SEND_OR_RESEND_INSTA_APPLICANT_CONSENT_OTP,
		),
		isOTPVerified: checkForDoneAction(
			ui.doneActions,
			actions.VERIFY_INSTA_APPLICANT_CONSENT_OTP,
		),
	};
};

const mapDispatchToProps = {
	createInstaApplicationB2C,
	sendOrResendInstaApplicantConsentOtp,
	verifyInstaApplicantConsentOtp,
	getOrCreateInstaApplicationDraft,
	removeDoneActions,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>
type Props = ReduxProps & {
    onSetStep: (step: number) => void
    onResendMobileOtp: (step: number) => void
    mobileNo: string
}
const Step5 = (props: Props) => 
{
	const [otpSent, setOtpSent] = useState(false);
	const [userInstaCreationCompleted, setUserInstaCreationCompleted] =
        useState(false);

	const trackingDetails = () => ({
		userId: applicationDraft?.UserId,
		instaId: applicationDraft?.InstaId,
		mobileNo: userApplication?.Mobile,
	});

	const {
		applicationDraft,
		createInstaApplicationB2C,
		sendOrResendInstaApplicantConsentOtp,
		verifyInstaApplicantConsentOtp,
		isUserInstaCompleted,
		sendOrResendOTPLoader,
		isOTPSent,
		isOTPVerified,
		removeDoneActions,
		otpVerifyLoader,
		isUserInstaCreating,
		userApplication,
		creditEligibilityCode,
	} = props;

	const instaDraft = userApplication?.Application?.InstaApplicationDraft;

	useEffect(() => 
	{
		if (isOTPSent) 
		{
			setOtpSent(true);
			removeDoneActions([
				actions.SEND_OR_RESEND_INSTA_APPLICANT_CONSENT_OTP,
			]);
		}

		if (isOTPVerified && applicationDraft) 
		{
			//@ts-ignore
			fbq("track", "ConsentDetails");
			// createInstaApplicationB2C();
			removeDoneActions([actions.VERIFY_INSTA_APPLICANT_CONSENT_OTP]);
		}

		if (isUserInstaCompleted) 
		{
			setUserInstaCreationCompleted(true);
			if (creditEligibilityCode) 
			{
				if (creditEligibilityCode === EligibilityCodes.PRE_APPROVED) 
				{
					//@ts-ignore
					fbq("track", "PreApproved");
				}
				else if (
					creditEligibilityCode === EligibilityCodes.PRE_QUALIFIED
				) 
				{
					//@ts-ignore
					fbq("track", "PreQualified");
				}
				else 
				{
					//@ts-ignore
					fbq("track", "MannualApproved");
				}
			}
			removeDoneActions([actions.CREATE_INSTA_APPLICATION_B2C]);
		}
	}, [isOTPSent, isOTPVerified, isUserInstaCompleted]);

	useEffect(() => 
	{
		//@ts-ignore
		fbq("track", "KYCComplete");
	}, []);

	const onProceed = () => 
	{
		if (userApplication && applicationDraft) 
		{
			sendOrResendInstaApplicantConsentOtp({
				InstaId: applicationDraft.InstaId,
				MobileNumber: userApplication.Mobile,
				IsCoBorrower: false,
			});
		}
	};

	const resultMapping = (code: number) => 
	{
		if (code) 
		{
			switch (code) 
			{
			case EligibilityResponses.PRE_APPROVED:
				return (
					<Result
						status="success"
						title={"Congratulations"}
						secondtitle={"You have been pre-approved"}
						subTitle={
							"You have successfully finished registering your details and our agent will get in touch with you to finish the rest of the process"
						}
					>
                            You can check your Application Process here at{" "}
						<span>
							<a href="www.propelld.com">www.propelld.com</a>
						</span>
					</Result>
				);
			case EligibilityResponses.PRE_QUALIFIED:
				return (
					<Result
						status="success"
						title={"Congratulations"}
						secondtitle={"You have been pre-approved"}
						subTitle={
							"You have successfully finished registering your details and our agent will get in touch with you to finish the rest of the process"
						}
					>
                            You can check your Application Process here at{" "}
						<span>
							<a href="www.propelld.com">www.propelld.com</a>
						</span>
					</Result>
				);
			case EligibilityResponses.MANUAL_APPROVED:
				return (
					<Result
						status="success"
						title={"Congratulations"}
						secondtitle={
							"You have successfully finished your application"
						}
						subTitle={
							"Our agent will get in touch with you for further process"
						}
					>
						<span>
							<a href="www.propelld.com">www.propelld.com</a>
						</span>
					</Result>
				);
			default:
				return (
					<Result
						status="success"
						title={
							"Given your Credit History, your loan request has been sent for manual approval"
						}
						subTitle={
							"Our agent will get in touch with you to finish the rest of the process if manual approval is successful"
						}
					></Result>
				);
			}
		}
	};

	return (
		<div className="form-container">
			{creditEligibilityCode &&
                // <div className="form--successBox">
                // 	<div style={{ fontWeight: "900" }}>Congratulations</div>
                // 	<div>
                //         You have successfully finished registering your details
                //         and our agent will get in touch with you to finish the
                //         rest of the process.
                // 	</div>
                // 	<div>
                //         You can check your Application Process here at{" "}
                // 		<span>
                // 			<a href="www.propelld.com">www.propelld.com</a>
                // 		</span>
                // 	</div>
                // </div>
                resultMapping(creditEligibilityCode)}
		</div>
	);
};

export default connector(Step5);
